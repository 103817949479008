export const white = '#ffffff';
export const gray0 = '#fbfbfb';
export const gray1 = '#f8f9fa';
export const gray2 = '#e9ecef';
export const gray3 = '#dee2e6';
export const gray4 = '#ced4da';
export const gray5 = '#adb5bd';
export const gray6 = '#6c757d';
export const gray7 = '#495057';
export const gray8 = '#343a40';
export const gray9 = '#212529';
export const gray10 = '#404040';
export const gray11 = '#373737';
export const gray12 = '#F6F6F6';
export const gray13 = '#808883';
export const gray14 = '#EFEFEF;';
export const black = '#000000';
export const facebookBlue = '#4267b2';
export const facebookBlueHover = '#3b5da0';
export const googleRed = '#ea4335';
export const googleRedHover = '#e72b1b';
export const green1 = '#10bb77';
export const green2 = '#0db14b';
export const green3 = '#0e6630';
export const blue = '#007bff';
export const darkBlue = '#0056b3';
export const red = '#f03030';
export const red2 = '#E11717';
export const purple = '#b290f0';
export const yellow = '#fef0ba';
export const yellow1 = '#dccf0f';
