// import * as moment from 'moment-timezone';
import * as React from 'react';

import { ICheckoutOrderProduct } from '../../../graphql/types/checkoutOrders';
import { ProductItem } from './styled';

export default (props: { orderProduct: ICheckoutOrderProduct }) => {
  const {
    orderProduct,
  } = props;

  return (
    <ProductItem>
      { orderProduct.product.name }
      {/* <div>
        De { moment.tz(orderProduct.start, 'UTC').format('DD/MM/YYYY') }&nbsp;
        até { moment.tz(orderProduct.end, 'UTC').format('DD/MM/YYYY') }
      </div> */}
    </ProductItem>
  );
};
