import { Box } from '@mui/material';
import * as React from 'react';
import Protect from '../../components/Protect';
import MainLayout from '../../layouts/Main';
import ProfileHeader from './components/ProfileHeader';

class ProfileLayout extends React.Component {
  public render() {
    return (
      <Protect>
        <MainLayout darkMode={true} internal={true}>
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <ProfileHeader />
            <Box sx={{ minWidth: {xs: '100%', md: '560px'}, paddingX: {xs: '20px', md: '0px'} }}>
              {this.props.children}
            </Box>
          </Box>
        </MainLayout>
      </Protect>
    );
  }
}

export default ProfileLayout;
