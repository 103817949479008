import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ActionCreator, AnyAction, Dispatch } from 'redux';
import { SET_ORDER_CONFIRMATION } from '../../../actionReducers/General';
import { IUserAction, LOG_USER } from '../../../actionReducers/User';
import { BlackBox, BlackBoxClose, CommonDiv } from '../../../components/styled';
import { State as CheckoutOrdersState } from '../../../graphql/types/checkoutOrders';
import MainLayout from '../../../layouts/Main';
import { IAppState } from '../../../store';
import ThankYouModal from '../components/thankYou';
import OrdersList from './OrdersList';
import {
  Container, NextOrdersHeader,
  OldOrdersHeader,
  OrdersWrapper
} from './styled';
interface IState {
  modalOpen: boolean;
  orderOpenModal?: string;
}

interface IProps {
  orderConfirmation: boolean;
  loggedState: boolean;
  setOrderConfirmation: (state: boolean) => void;
  logUser: ActionCreator<IUserAction>;
}

class Orders extends React.Component<IProps & RouteComponentProps<{}>, IState> {
  // private intervalToGetStatusIntegration;
  public state: IState = {
    modalOpen: false
  };

  public componentDidMount() {
    const { loggedState, history } = this.props;
    const query = new URLSearchParams(this.props.location.search);
    const orderOpenModal = query.get('pedido');
    const token = query.get('token');
    if (orderOpenModal) this.setState({ orderOpenModal });

    if (!loggedState && token) this.props.logUser(token);
    else if (!loggedState && !token) history.push('/login?go=pedidos');
  }

  public componentDidUpdate() {
    const { orderConfirmation, history } = this.props;

    if (orderConfirmation && !this.state.modalOpen && location.pathname === '/agradecimento') {
      return (
        this.setState({ modalOpen: true })
      );
    }

    if (this.state.modalOpen === false && location.pathname !== '/pedidos/agendados') {
      history.push('/pedidos/agendados');
    }
  }

  public closeModal = () => {
    const { setOrderConfirmation, history } = this.props;

    this.setState({ modalOpen: false });
    history.push('/pedidos/agendados');
    setOrderConfirmation(false);
  }

  public clearQuery = () => {
    const { history } = this.props;
    const queryParams = new URLSearchParams(location.search);

    queryParams.delete('pedido');
    queryParams.delete('token');
    history.replace({
      search: queryParams.toString()
    });

    this.setState({ orderOpenModal: '' });
  }

  public render() {
    return (
      <>
        <CommonDiv>
          {this.state.modalOpen ? (
            <BlackBox onClick={this.closeModal} />
          ) : (
            <BlackBoxClose />
          )}
          {this.state.modalOpen ? (
            <ThankYouModal close={this.closeModal} />
          ) : null}
        </CommonDiv>
        
        <MainLayout hideSearch={true} internal={true}>
          <Container>

            <OrdersWrapper>
              <NextOrdersHeader>Próximas Assinaturas</NextOrdersHeader>

              <OrdersList
                old={false}
                history={this.props.history}
                orderOpenModal={this.state.orderOpenModal}
                clearQuery={() => this.clearQuery()}
              />

              <OldOrdersHeader>Assinaturas em Andamento</OldOrdersHeader>

              <OrdersList
                old={false}
                state={CheckoutOrdersState.pedidoEntregue}
                history={this.props.history}
                orderOpenModal={this.state.orderOpenModal}
                clearQuery={() => this.clearQuery()}
              />

              <OldOrdersHeader>Assinaturas Finalizadas</OldOrdersHeader>

              <OrdersList
                old={true}
                history={this.props.history}
                orderOpenModal={this.state.orderOpenModal}
                clearQuery={() => this.clearQuery()}
              />
            </OrdersWrapper>
          </Container>
        </MainLayout>
      </>
    );
  }
}

export default withRouter(connect((state: IAppState) => ({
  orderConfirmation: state.general.orderConfirmed,
  loggedState: state.user.logged,
}), (dispatch: Dispatch<AnyAction>) => ({
  logUser: (token: string) => dispatch(LOG_USER(token)),
  setOrderConfirmation: (orderConfirmation: boolean) => dispatch(SET_ORDER_CONFIRMATION(orderConfirmation)),
}))(Orders));
