import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import React, { SetStateAction, useState } from 'react';
import { Mutation, WithApolloClient, withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { ActionCreator, Dispatch } from 'redux';
import { ICartAction, REMOVE_SELECTED_ADDRESS } from '../../../actionReducers/Cart';
import {
  IMutationRemoveAddress,
  MUTATION_EDIT_ADDRESS,
  MUTATION_REMOVE_ADDRESS,
} from '../../../graphql/mutations/users';
import { IUserAddress } from '../../../graphql/types/users';
import {
  ProfileAddressBox,
} from '../components/styled';
import ProfileAddAddressForm from './ProfileAddAddressForm';
import { Edit } from '@mui/icons-material';

interface IProfileAddressProps {
  removeSelectedAddress: ActionCreator<ICartAction>;
  setShowFormAddress: React.Dispatch<SetStateAction<boolean>>;
  address: IUserAddress;
  refetch: (...args: any[]) => any;
}

const ProfileAddress = (props: IProfileAddressProps & WithApolloClient<{}>) => {
  const { address, setShowFormAddress } = props;
  const [editAddress, setEditAddress] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleAddressEdit = () => {
    setEditAddress(!editAddress);
  };


  const renderAddressEdit = () => {
    const { address, refetch } = props;

    if (editAddress) {
      return (
        <Mutation
          key={'editAddressFormMutation'}
          mutation={MUTATION_EDIT_ADDRESS}
        >
          {
            (edit) =>
              <ProfileAddAddressForm
                submit={edit}
                address={address}
                refetch={refetch}
                toggleEdit={toggleAddressEdit}
                setShowFormAddress={setShowFormAddress}
              />
          }
        </Mutation>
      );
    }

    return null;
  }

  const removeAddress = async (identifier: string) => {
    const { client, refetch } = props;
    setLoading(true);
    await client.mutate<IMutationRemoveAddress>({
      mutation: MUTATION_REMOVE_ADDRESS,
      variables: { identifier }
    });
    setLoading(false);
    await refetch();
  }

  return (
    <>
      <ProfileAddressBox key={`addressBox-${address.identifier}`}>
        <Stack spacing={'28px'} sx={{ width: '100%', padding: '16px' }}>
          <Box>
            <Typography variant='h5'>{address.code.length === 8 ? address.code.replace(/(\d{5})?(\d{3})/, '$1-$2') : address.code}</Typography>
            <Typography variant='body2' color={'rgba(0, 0, 0, 0.6)'}>{address.street}, {address.number}, {address.neighborhood}, {address.city}, {address.state}</Typography>
          </Box>
          <Stack direction={'row'} spacing={'18px'}>
            <Button variant='text' sx={{ fontWeight: 500 }} startIcon={<Edit />} onClick={() => toggleAddressEdit()}>EDITAR ENDEREÇO</Button>
            <Button
              variant='text'
              sx={{ color: '#D32F2F', fontWeight: 500 }}
              onClick={() => removeAddress(address.identifier)}
              disabled={loading}
            >
              {loading ? <CircularProgress size={20} sx={{ color: '#D32F2F' }} /> : 'EXCLUIR'}
            </Button>
          </Stack>
        </Stack>
      </ProfileAddressBox>
      {renderAddressEdit()}
    </>
  );
}

export default connect(null, (dispatch: Dispatch<ICartAction>) => ({
  removeSelectedAddress: () => dispatch(REMOVE_SELECTED_ADDRESS()),
}))(withApollo(ProfileAddress));
