import { ICreditCard, PaymentType } from "../actionReducers/Checkout";
import { IPayment } from "../actionReducers/Recurrence";
import { IPublicUser, IUserAddress } from "../graphql/types/users";
import { IFormikPersonalDataRecurrenceFields } from "../routes/RecurrenceCard/PersonalData";
import checkAge from './checkAge';
import validateCardExpiration from "./validateCardExpiration";


/* Helper momentâneo, usado para validar se dados pessoais de usuário foram preenchidos e validar liberação do step de pagamento na tela de checkout.*/
export const validatePersonalDataUserStepCheckout = (personalData: IPublicUser) => {
  if (!personalData) return false;

  const {
    personalData: { firstName, lastName, document, birthday },
    phone,
    email,
  } = personalData;
  if (!checkAge(birthday)) return false;
  if (!document || !firstName || !lastName || !phone || !email || !birthday) return false;
  if (!document.trim().length || !firstName.trim().length || !lastName.trim().length || !phone.trim().length || !email.trim().length || !birthday) return false;
  return true;
};

export const validatePersonalAddressStepCheckout = (
  personalAddress?: IUserAddress
) => {
  if (!personalAddress) return false;
  const { city, code, neighborhood, number, state, street } = personalAddress;
  if (!city || !code || !neighborhood || !number || !state || !street)
    return false;
  return true;
};

export const validateDeliveryAddressStepCheckout = (
  deliveryAddress?: IUserAddress
) => {
  if (!deliveryAddress) return false;
  const { city, code, neighborhood, number, state, street } = deliveryAddress;
  if (!city || !code || !neighborhood || !number || !state || !street || code.length < 8)
    return false;
  return true;
};


export const validatePaymentStepCheckout = (
  creditCard?: ICreditCard,
  typePayment?: PaymentType
) => {
  if (typePayment === PaymentType.BOLETO || typePayment === PaymentType.PIX) return true;
  else {
    if (!creditCard || creditCard.errors) return false;
    const { cvv, expirationDate, holderName, installments, number } = creditCard;
    if (!expirationDate || !holderName || !installments || !number || !cvv || !validateCardExpiration(expirationDate)) {
      return false;
    }
    return true;
  }
};

export const validatePersonalDataRecurrence = (
  personalData: IFormikPersonalDataRecurrenceFields
) => {
  const {
    document,
    phone,
  } = personalData;
  if (!document || !phone) return false;
  if (!document.trim().length || !phone.trim().length) return false;
  return true;
};

export const validatePaymentRecurrenceStepCheckout = (
  paymentEntry: IPayment,
  paymentMonthly: IPayment,
  singleCard?: boolean
) => {
  if (!paymentEntry.amount && paymentMonthly.creditCard && !paymentMonthly.creditCard.errors) return true;
  if (paymentEntry.type === PaymentType.BOLETO && paymentMonthly.creditCard && !paymentMonthly.creditCard.errors) return true;
  if (paymentEntry.type === PaymentType.CREDIT_CARD
    && singleCard
    && paymentEntry.creditCard
    && !paymentEntry.creditCard.errors
  ) return true;
  if (paymentEntry.type === PaymentType.CREDIT_CARD
    && !singleCard
    && paymentEntry.creditCard
    && !paymentEntry.creditCard.errors
    && paymentMonthly.creditCard
    && !paymentMonthly.creditCard.errors
  ) return true;
  return false
};