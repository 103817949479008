import { Add } from '@mui/icons-material';
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { QueryResult } from 'react-apollo';

import { IQueryCurrentUser } from '../../../graphql/queries/users';
import { IUserCards } from '../../../graphql/types/users';
import EnumGateway from "../../../helpers/enumGateway";
import ProfileCards from './ProfileCards';

interface IProfileCards {
  queryResult: QueryResult<IQueryCurrentUser>;
  showFormCards: boolean;
  setShowFormCards: Dispatch<SetStateAction<boolean>>;
}

const ProfileCardsSection = ({ queryResult, showFormCards, setShowFormCards }: IProfileCards) => {

  const renderCards = (card: IUserCards) => {
    return <ProfileCards key={card.id} card={card} refetch={queryResult.refetch} setShowFormCards={setShowFormCards} />
  }

  const { loading, data } = queryResult;

  let cards = null;

  if (!loading && data) {
    cards = data.currentUser.cards.filter((c) => c.transactionGateway === EnumGateway.pagarmev3).map(renderCards);
  }

  return (
    <>
      {
        loading ? (
          <Stack sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: "center", padding: "30px" }}>
            <CircularProgress sx={{ color: '#27AE60' }} size={'40px'} />
          </Stack>
        ) : (
          <Box>
            <Typography fontFamily={'Chillax'} fontWeight={500} fontSize={'21px'} sx={{ color: '#27AE60', padding: '48px 0 24px' }}>Meus cartões</Typography>
            {
              !cards.length ? (
                <Typography fontFamily={'Chillax'} fontWeight={400} fontSize={'16px'} >Você ainda não tem um cartão cadastro.</Typography>

              ) : (
                <Stack spacing={'24px'}>
                  {cards}
                </Stack>
              )
            }
            {
              !showFormCards ? (
                <Box sx={{ padding: '48px 0 72px' }}>
                  <Button variant='outlined' sx={{ color: '#27AE60' }} startIcon={<Add />} onClick={() => setShowFormCards(true)}>ADICIONAR CARTÃO</Button>
                </Box>
              ) : null
            }
          </Box>
        )
      }
    </>
  );
}

export default ProfileCardsSection;
