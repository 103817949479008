import * as yup from 'yup';

export const validatorPersonalDataRecurrence = yup.object().shape({
    phone: yup
        .string()
        .nullable(true)
        .required('Necessário informar número de telefone')
        .min(11, 'Número de telefone inválido'),
    document: yup.string().nullable(true).required('Necessário informar número de CPF').min(11, 'Número de CPF inválido'),
});
