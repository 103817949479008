import React from 'react';
import { Link } from '@mui/material';
import { Container } from './styles';
import Config from '../../../config';

export default function Footer() {
  return (
    <Container>
      <Link href={Config.termosDeUsoUrl} target='_blank'>Termos de Uso</Link>
      <Link href={Config.avisoProtecaoDadosUrl} target='_blank'>Aviso de Proteção de Dados</Link>
      <b>©2024 Allugator  CNPJ:25.053.921/0001-43</b>
    </Container>
  )
}