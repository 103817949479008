import React, { useEffect, useState } from 'react';
import { AssignmentOutlined } from "@mui/icons-material"
import { Box, Button, Checkbox, CircularProgress, Divider, Grid, Link, Stack, Typography } from "@mui/material"
import { IProduct } from '../../../graphql/types/products';
import { connect } from 'react-redux';
import { IAppState } from '../../../store';
import { getProductShortName } from '../../../helpers/getProductShortName';
import getStorage from '../../../helpers/getStorageProduct';
import toReal from '../../../helpers/toReal';
import { IPayment } from '../../../actionReducers/Recurrence';
import { validatePaymentRecurrenceStepCheckout } from '../../../helpers/validateDataUser';
import { ICheckoutOrderProduct, ISubscriptionPayment } from '../../../graphql/types/checkoutOrders';
import { PaymentType } from '../../../actionReducers/Checkout';
import Config from '../../../config';

interface IProps {
    orderProduct: ICheckoutOrderProduct[];
    loading: boolean;
    acceptedTerms: boolean;
    singleCard: boolean;
    paymentEntry: IPayment;
    paymentMonthly: IPayment;
    subscriptionPayment: ISubscriptionPayment;
    setAcceptedTerms: (value: boolean) => void;
    submit: () => void;
}

const ResumeCheckout = ({
    orderProduct,
    loading,
    singleCard,
    acceptedTerms,
    paymentEntry,
    paymentMonthly,
    subscriptionPayment: { entryFeeValue },
    submit,
    setAcceptedTerms
}: IProps) => {

    const [product, setProduct] = useState<IProduct>();

    useEffect(() => {
        if (orderProduct && orderProduct.length) {
            setProduct(orderProduct[0].product);
        }
    }, [orderProduct])

    return (
        <Grid
            item
            padding={'24px'}
            sx={{
                boxShadow: '0px 5px 8px rgba(120, 111, 111, 0.15)',
                borderRadius: '8px',
            }}
        >
            <Box display={'flex'} gap={'10px'}>
                <AssignmentOutlined color={'action'} />
                <Typography variant="body1" fontSize={'17px'}>
                    RESUMO DO PEDIDO
                </Typography>
            </Box>
            <Box marginTop={'24px'} display={'flex'} justifyContent={'space-between'}>
                {product && product.photos && product.photos.length && (
                    <img
                        src={product.photos[0]}
                        width={'86'}
                        height={'116'}
                        alt={'Produto'}
                    />
                )}
                <Box display={'flex'} flexDirection={'column'} alignItems={'end'}>
                    <Typography variant={'subtitle1'} color={'#333333'} fontSize={'17px'}>
                        Produto
                    </Typography>
                    <Typography variant={'subtitle2'} color={'#333333'} fontSize={'15px'}>
                        {product && product.name && getProductShortName(product.name)}
                    </Typography>
                    <Typography variant={'subtitle2'} color={'#666666'} fontSize={'15px'}>
                        {product && product.name && getStorage(product.name).label}
                    </Typography>
                </Box>
            </Box>
            <Divider variant="fullWidth" color="#E4E4E4" sx={{ marginY: '20px' }} />
            <Stack spacing={'8px'}>
                {
                    entryFeeValue ? (
                        <Box display={'flex'} justifyContent={'space-between'}>
                            <Typography variant={'subtitle2'} color={'#333333'} fontSize={'15px'}>
                                Entrada
                            </Typography>
                            <Typography variant={'subtitle1'} color={'#666666'} fontSize={'15px'}>
                                {toReal(paymentEntry.amount)}
                            </Typography>
                        </Box>
                    ) : null
                }
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography variant={'subtitle2'} color={'#333333'} fontSize={'15px'}>
                        Assinatura
                    </Typography>
                    <Typography variant={'subtitle2'} color={'#666666'} fontSize={'15px'}>
                        {
                        paymentEntry.type === PaymentType.CREDIT_CARD 
                        && singleCard && entryFeeValue ? '11 x' : '12 x'}&nbsp;{toReal(paymentMonthly.amount)}
                    </Typography>
                </Box>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography variant={'subtitle2'} color={'#333333'} fontSize={'15px'}>
                        Frete
                    </Typography>
                    <Typography variant={'subtitle2'} color={'#666666'} fontSize={'15px'}>
                        Grátis
                    </Typography>
                </Box>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography variant={'subtitle2'} color={'#333333'} fontSize={'15px'}>
                        Tempo de assinatura
                    </Typography>
                    <Typography variant={'subtitle2'} color={'#666666'} fontSize={'15px'}>
                        12 meses
                    </Typography>
                </Box>
            </Stack>
            <Divider variant="fullWidth" color="#E4E4E4" sx={{ marginY: '20px' }} />
            <Box sx={{ display: 'flex' }} alignItems={'center'}>
                <Checkbox
                    defaultChecked
                    disabled={!validatePaymentRecurrenceStepCheckout(paymentEntry, paymentMonthly, singleCard)}
                    value={acceptedTerms}
                    checked={acceptedTerms}
                    onChange={() => setAcceptedTerms(!acceptedTerms)}
                />
                <Typography variant="caption" fontSize={13}>
                    Estou de acordo com os{' '}
                    <Link
                        href={Config.termosDeUsoUrl}
                        target="_blank"
                    >
                        Termos de Uso
                    </Link>{' '}
                    e{' '}
                    <Link
                        href={Config.avisoProtecaoDadosUrl}
                        target="_blank"
                    >
                        Aviso de Proteção de Dados
                    </Link>{' '}
                    da Allugator relativos à assinatura.
                </Typography>
            </Box>
            <Divider variant="fullWidth" color="#E4E4E4" sx={{ marginY: '20px' }} />
            <Button
                variant="contained"
                fullWidth
                size="large"
                color="primary"
                disabled={!validatePaymentRecurrenceStepCheckout(paymentEntry, paymentMonthly, singleCard) || !acceptedTerms || loading ? true : false}
                onClick={() => submit()}
            >
                {!loading ? 'Finalizar' : <CircularProgress size={'20px'} />}
            </Button>
        </Grid>
    )
};

export default connect((state: IAppState) => ({
    loading: state.general.loading,
    singleCard: state.checkoutRecurrence.singleCard,
    paymentEntry: state.checkoutRecurrence.paymentEntry,
    paymentMonthly: state.checkoutRecurrence.paymentRecurrence,
    orderProduct: state.checkoutRecurrence.orderProduct,
    subscriptionPayment: state.checkoutRecurrence.order.subscriptionPayment
}))(ResumeCheckout);