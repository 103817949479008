import React, { Dispatch, SetStateAction } from 'react';
import { AccountCircleOutlined } from '@mui/icons-material';
import { Box, Button, CircularProgress, Divider, Stack, Typography, Link } from '@mui/material';
import DataUserSummary from './summary';
import PersonalDataForm from './form';
import { Formik } from 'formik';
import { Fragment,  useState } from 'react';
import { connect } from 'react-redux';
import { withApollo, WithApolloClient } from 'react-apollo';
import { toast } from 'react-toastify';
import { IAppState } from '../../../store';
import { validatorPersonalDataRecurrence } from '../../../validators/validatorPersonalDataRecurrence';

export interface IFormikPersonalDataRecurrenceFields {
    document: string;
    phone: string;
};

interface IProps {
    loading: boolean;
    personalData: IFormikPersonalDataRecurrenceFields;
    setPersonalData: Dispatch<SetStateAction<IFormikPersonalDataRecurrenceFields>>
}

const PersonalData = ({ loading, personalData, setPersonalData }: IProps & WithApolloClient<{}>) => {
    const [edit, setEdit] = useState<boolean>(true);
    const [activeAllFields, setActiveAllFields] = useState<boolean>(false);

    const submit = async (values: IFormikPersonalDataRecurrenceFields) => {
        try {
            setPersonalData(values);
            setActiveAllFields(true);
            setEdit(false);
        } catch (err) {
            toast.error('Erro ao alterar dados do usuário.');
            return;
        }
    };

    return (
        <Box sx={{ paddingBottom: '24px' }}>
            {loading ? (
                <Box width={'100%'} sx={{ display: 'flex', justifyContent: 'center', padding: '30px' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Fragment>
                    <Stack spacing={'20px'}>
                        <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            spacing={'10px'}
                        >
                            <AccountCircleOutlined sx={{ opacity: 0.5 }} />
                            <Typography variant="body1" fontSize={17}>
                                DADOS PESSOAIS E DE ENTREGA
                            </Typography>
                            <Link
                                variant="caption"
                                color={'#000000'}
                                fontSize={13}
                                onClick={() => setEdit(true)}
                                sx={{ cursor: 'pointer' }}
                            >
                                Editar
                            </Link>
                        </Stack>
                        {!edit ? (
                            loading ? (
                                <Box width={'100%'} sx={{ display: 'flex', justifyContent: 'center', padding: '30px' }}>
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <DataUserSummary {...personalData} />
                            )
                        ) : (
                            <Formik
                                initialValues={personalData}
                                onSubmit={(values: IFormikPersonalDataRecurrenceFields) => submit(values)}
                                validationSchema={validatorPersonalDataRecurrence}
                            >
                                {(formikProps) => (
                                    <Stack spacing={'24px'}>
                                        <PersonalDataForm {...formikProps} submitByEnter={() => formikProps.handleSubmit()} />
                                        <Stack direction="row" spacing={'24px'}>
                                            {activeAllFields ? (
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    size="large"
                                                    sx={{ width: '100%' }}
                                                    onClick={() => setEdit(false)}
                                                >
                                                    CANCELAR
                                                </Button>
                                            ) : null}
                                            <Button
                                                variant="contained"
                                                size="large"
                                                color="primary"
                                                sx={{ width: '100%' }}
                                                onClick={() => formikProps.handleSubmit()}
                                            >
                                                SALVAR
                                            </Button>
                                        </Stack>
                                    </Stack>
                                )}
                            </Formik>
                        )}
                    </Stack>
                    <Divider sx={{ paddingTop: '24px' }} />
                </Fragment>
            )}
        </Box>
    );
};

export default connect((state: IAppState) => ({
    loading: state.general.loading,
}))(withApollo(PersonalData));
