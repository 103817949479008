import React from 'react';
import { MonetizationOnOutlined } from '@mui/icons-material';
import { Box, Grid, Stack, Typography, Icon } from '@mui/material';
import { IUserAddress } from '../../../graphql/types/users';
import { validateDeliveryAddressStepCheckout, validatePersonalDataRecurrence } from '../../../helpers/validateDataUser';
import { IFormikPersonalDataRecurrenceFields } from '../PersonalData';
import EntryPayment from './Entry';
import RecurrencePayment from './Recurrence';
import { connect } from 'react-redux';
import { IAppState } from '../../../store';
import { PaymentType } from '../../../actionReducers/Checkout';
import { ISubscriptionPayment } from '../../../graphql/types/checkoutOrders';

interface IProps {
    dataPersonal: IFormikPersonalDataRecurrenceFields;
    deliveredAddress: IUserAddress;
    singleCard: boolean;
    paymentEntry: PaymentType;
    subscriptionPayment: ISubscriptionPayment;
}

const handleBlurFields = (field: string, value: string | number, errors: any) => {
    if (!value) return;
    const data = {};
    data[field] = value;
    data['errors'] = Object.keys(errors).length ? true : false;
    return data;
};

const PaymentStep = ({
    deliveredAddress,
    dataPersonal,
    subscriptionPayment: { entryFeeValue }
}: IProps) => {
    return (
        <Grid
            container
            padding={'24px'}
            borderRadius={'8px'}
            boxShadow={'0px 5px 8px rgba(120, 111, 111, 0.15)'}
        >
            <Grid item sm={12} width={'100%'}>
                <Stack spacing={'24px'}>
                    {!deliveredAddress || !dataPersonal ||
                        !validatePersonalDataRecurrence(dataPersonal) ||
                        !validateDeliveryAddressStepCheckout(deliveredAddress) ? (
                        <Stack spacing={'24px'}>
                            <Box display={'flex'} justifyContent={'space-between'}>
                                <Grid display={'flex'}>
                                    <Icon color={'action'}>
                                        <MonetizationOnOutlined />
                                    </Icon>
                                    <Typography
                                        variant="body1"
                                        fontSize={17}
                                        marginLeft={'10px'}
                                        color={'#30313F'}
                                    >
                                        FORMA DE PAGAMENTO 
                                    </Typography>
                                </Grid>
                            </Box>
                            <Typography variant={'caption'} fontSize={13} color={'#666666'}>
                                Aguardando o preenchimento dos dados Dados de endereço
                            </Typography>
                        </Stack>
                    ) : (
                        <React.Fragment>
                            {
                                entryFeeValue
                                    ? <EntryPayment handleBlurFields={handleBlurFields} />
                                    : null
                            }
                            <RecurrencePayment handleBlurFields={handleBlurFields} />
                        </React.Fragment>
                    )}
                </Stack>
            </Grid>
        </Grid>
    );
};

export default connect((state: IAppState) => ({
    singleCard: state.checkoutRecurrence.singleCard,
    paymentEntry: state.checkoutRecurrence.paymentEntry.type,
    subscriptionPayment: state.checkoutRecurrence.order.subscriptionPayment
}))(PaymentStep);
