import { ApolloQueryResult } from 'apollo-client';
import { History } from 'history';
import * as moment from 'moment-timezone';
import * as React from 'react';

import { BlackBox, BlackBoxClose } from '../../../components/styled';
import { IQueryMyOrders } from '../../../graphql/queries/checkoutOrders';
import { ICheckoutOrder, ICheckoutOrderProduct } from '../../../graphql/types/checkoutOrders';
import toReal from '../../../helpers/toReal';
import OrderDetail from './OrderDetail';
import {
  OrderProductCard,
  OrderProductCardInfo,
  OrderProductCardPrice
} from './styled';

interface IProps {
  next?: boolean;
  order: ICheckoutOrder;
  history?: History;
  orderOpenModal?: String
  clearQuery?:() => void;

  refetchOrders?: () => Promise<ApolloQueryResult<IQueryMyOrders>>;
}

interface IState {
  modalOpen: boolean;
  orderIndex: number;
}

class OrderProducts extends React.Component<IProps> {
  public state: IState = {
    modalOpen: false,
    orderIndex: 0,
  };

  public renderOrderProduct =
    ({ checkoutOrder, product, start, end, amountPaid }: ICheckoutOrderProduct, index: number) => {
      const startDate = moment.tz(start, 'UTC');
      const endDate = moment.tz(end, 'UTC');
      return (
        <OrderProductCard key={`${index}::${checkoutOrder}::${product}`}>
          <img src={product.photos[0]} />

          <OrderProductCardInfo>
            <div><strong>{product.name}</strong></div>
            <span>{endDate.diff(startDate, 'days')} dias</span>
          </OrderProductCardInfo>

          <OrderProductCardPrice>
            <button onClick={() => { this.setState({ orderIndex: index }); this.openModal(); }}>+informações</button>
            {toReal(amountPaid ? amountPaid : this.props.order.amount)}
          </OrderProductCardPrice>
        </OrderProductCard>
      );
    }

  public renderModal = () => {
    const { order, refetchOrders, next, orderOpenModal } = this.props;
    const { modalOpen, orderIndex } = this.state;

    if ((modalOpen || order.shortId === orderOpenModal) && order.checkoutOrderProducts[orderIndex]) {
      return [
        <OrderDetail
          refetchOrders={refetchOrders}
          key={`modal::${order._id}`}
          closeFn={this.closeModal}
          order={order}
          next={next}
          orderProduct={order.checkoutOrderProducts[orderIndex]}
          history={this.props.history}
        />,
        <BlackBox key={`blackBoxOpen::${order._id}`} onClick={this.closeModal} />,
      ];
    }

    return <BlackBoxClose key={`blackBoxClose::${order._id}`} />;
  }

  public openModal = () => {
    window.scrollTo(0, 0);
    this.setState({ modalOpen: true });
  }

  public closeModal = () => {
    const { clearQuery } = this.props;

    clearQuery();
    
    this.setState({ modalOpen: false });
  };

  public render() {
    const { order } = this.props;
    return [
      ...order.checkoutOrderProducts
        .sort((a, b) => new Date(a.start).getTime() < new Date(b.end).getTime() ? 1 : -1)
        .map(this.renderOrderProduct),
      this.renderModal()
    ];
  }
}

export default OrderProducts;
