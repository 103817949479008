import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router';
// import AlluFriends from './landings/AlluFriends';
// import AlluFriendsThanks from './landings/AlluFriends/AlluFriendsThanks';
// import Franchises from './landings/Franchises/';
// import Photography from './landings/Photography';
import ForgotPassword from './routes/Auth/ForgotPassword/';
import NewSignUp from './routes/Auth/NewSignUp';
import ResetPassword from './routes/Auth/ResetPassword/';
// import Cart from './routes/Cart/';
// import CheckoutBoleto from './routes/Checkout/CheckoutBoleto/';
// import CheckoutCheckoutCredCard from './routes/Checkout/CheckoutCredCard';
// import CheckoutCredCardAndBoleto from './routes/Checkout/CheckoutCredCardAndBoleto';
// import CheckoutPix from './routes/Checkout/CheckoutPix';
// import NewCheckout from './routes/CheckoutV2';
import DocumentsV2 from './routes/DocumentsV2';
import Thanks from './routes/DocumentsV2/Thanks';
// import FastCheckout from './routes/FastCheckout';
import FastCheckoutV2 from './routes/FastCheckoutV2';
// import Home from './routes/Home/';
// import HomeV2 from './routes/HomeV2';
// import HowToWorks from './routes/HowToWorks';
// import MemberGetMember from './routes/MemberGetMember';
import NewLogin from './routes/NewLogin';
import Orders from './routes/Orders/hermes';
import PaymentsRecurrence from './routes/PaymentsRecurrence';
// import ProductPage from './routes/ProductPage/';
import ProfileAccount from './routes/Profile/Account/';
import ProfileAddresses from './routes/Profile/Address/';
import ProfileCards from './routes/Profile/Cards/';
// import Proposal from './routes/Proposal/';
import RecurrenceCard from './routes/RecurrenceCard';
// import SearchProducts from './routes/SearchProducts';
// import SelectShippingAddress from './routes/SelectShippingAddress/';
// import Privacy from './routes/Terms/Privacy';
// import TermsLite from './routes/Terms/TermsLite';
// import EndCheckout from './routes/ThankYou/NewThanksCheckout';
import EndFastCheckout from './routes/ThankYou/NewThanksFastCheckout';
// import ThanksCheckout from './routes/ThankYou/ThanksCheckout';
// import ThanksCheckoutDocumentation from './routes/ThankYou/ThanksCheckoutDocumentation';
import EndCheckoutRecurrence from './routes/ThankYou/ThanksRecurrence';
// import ThanksRenew from './routes/ThankYou/ThanksRenew';
import ThanksReview from './routes/ThankYou/ThanksReview';

const style = { border: '' };

const YacareRouter: React.FC = () => (
  <div style={style}>
    <Switch>
      {/* <Route exact path='/buscar' component={SearchProducts} /> */}
      <Route exact path='/cadastrar' component={NewSignUp} />
      <Route exact path='/login' component={NewLogin} />
      <Route exact path='/esqueci-minha-senha' component={ForgotPassword} />
      <Route exact path='/nova-senha/:token' component={ResetPassword} />
      {/* <Route exact path='/produto/:id' component={ProductPage} /> */}
      {/* <Route exact path='/finalizar-aluguel' component={NewCheckout} /> */}
      <Route exact path='/perfil' component={ProfileAccount} />
      <Route exact path='/perfil/editar' component={ProfileAccount} />
      <Route exact path='/perfil/enderecos' component={ProfileAddresses} />
      <Route exact path='/perfil/cartoes' component={ProfileCards} />
      <Route exact path='/pedidos' component={Orders} />
      <Route exact path='/pedidos/agendados' component={Orders} />
      <Route exact path='/pedido-realizado' component={EndFastCheckout} />
      <Route exact path='/documents' component={DocumentsV2} />
      <Route exact path='/pedidos/anteriores' component={Orders} />
      {/* <Route exact path='/institucional' component={TermsLite} /> */}
      {/* <Route exact path='/institucional/politica-de-privacidade' component={Privacy} /> */}
      {/* <Route exact path='/institucional/termos-de-uso-lite' component={TermsLite} /> */}
      {/* <Route exact path='/verificacao-de-email/:token' component={Home} /> */}
      {/* <Route exact path='/carrinho' component={Cart} />
      <Route exact path='/finalizar-aluguel-cartao' component={CheckoutCheckoutCredCard} />
      <Route exact path='/finalizar-aluguel-boleto' component={CheckoutBoleto} />
      <Route exact path='/finalizar-aluguel-boleto-cartao' component={CheckoutCredCardAndBoleto} />
      <Route exact path='/finalizar-aluguel-pix' component={CheckoutPix} /> */}
      {/* <Route exact path='/metodo-de-retirada' component={SelectShippingAddress} />
      <Route exact path='/minifranquias' component={Franchises} />
      <Route exact path='/proposta/:id' component={Proposal} /> */}
      <Route exact path='/pagamentoRecorrente' component={RecurrenceCard} />
      {/* <Route exact path='/allufriends' component={AlluFriends} />
      <Route exact path='/allufriends/obrigado/:invite' component={AlluFriendsThanks} />
      <Route exact path='/photo' component={Photography} />
      <Route exact path='/thanks' component={ThanksCheckout} /> */}
      <Route exact path='/thanks-documents' component={Thanks} />
      <Route exact path='/thanks-review' component={ThanksReview} />
      {/* <Route exact path='/thanks-checkout-documentation' component={ThanksCheckoutDocumentation} /> */}
      {/* <Route exact path='/thanks-renew' component={ThanksRenew} />
      <Route exact path='/obrigado' component={EndCheckout} /> */}
      <Route exact path='/obrigado-recorrencia' component={EndCheckoutRecurrence} />
      {/* <Route exact path='/' component={HomeV2} /> */}
      {/* <Route exact path='/indicacao' component={MemberGetMember} /> */}
      {/* <Route exact path='/como-funciona' component={HowToWorks} /> */}
      <Route exact path='/meus-pagamentos' component={PaymentsRecurrence} />
      {/* <Route exact path='/finalizar-assinatura/:campaign' component={FastCheckout} /> */}
      <Route exact path='/finalizar-assinatura/:campaign' component={FastCheckoutV2} />
      <Route exact path='/finalizar-assinatura/parceiro/:campaign' component={FastCheckoutV2} />
      <Redirect to='/pedidos' />
    </Switch>
  </div>
);

export default YacareRouter;
