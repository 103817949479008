import React from "react";

// import logo from "../../../../assets/img/FastCheckout/mercado-pago-cartões.png"
import logo from "../../../../assets/img/Footer/pagarme_oficial.png";

import { Box, Typography } from "@mui/material";
import { ORIGIN_FAST_CHECKOUT } from "../../../../boot/constants";
import Cookies from "../../../../helpers/Cookies";

function Footer() {
    const partnersThatNotAllowMPBanner = ['picpay'];

    const isToDisplayMPBanner = () => {
      const origin = Cookies.get(ORIGIN_FAST_CHECKOUT) || '';

      return !partnersThatNotAllowMPBanner.includes(origin.toLocaleLowerCase() ?? '');
    }

    return (
        <Box display="flex" gap={3} flexDirection="column">
            {isToDisplayMPBanner() && (
              <Box display="flex" alignItems="center">
                <Typography fontFamily='Chillax' color="1E1E1E">Powered by:</Typography>
                <img src={logo} width='183px' alt="logo_pagarme" />
              </Box>
            )}
            <Box>
                <Typography color="#757575" fontFamily="Chillax" mb={1} fontSize={12}>@2023 - Allugator - Assinatura de Eletrônicos.</Typography>
                <Typography color="#757575" fontFamily="Chillax" fontSize={12}>CNPJ: 25.053.921/0001-43</Typography>
            </Box>
        </Box>
    )
}

export default Footer
