import { Box, Stack, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

enum TabsEditPersonalData {
  PERSONAL_DATA,
  ADDRESS_DATA,
  CARDS_DATA
}

const ProfileHeader: React.SFC<RouteComponentProps<{}>> = (props) => {
  const { history, location } = props;
  const [tab, setTab] = useState(0);

  useEffect(() => {
    switch (location.pathname) {
      case '/perfil':
        return setTab(0);
      case '/perfil/editar':
        return setTab(0);
      case '/perfil/enderecos':
        return setTab(1);
      case '/perfil/cartoes':
        return setTab(2);
    }
  }, [location]);

  const handleChange = (_, newTab: TabsEditPersonalData) => {
    switch (newTab) {
      case TabsEditPersonalData.PERSONAL_DATA:
        return history.push('/perfil/editar');
      case TabsEditPersonalData.ADDRESS_DATA:
        return history.push('/perfil/enderecos');
      case TabsEditPersonalData.CARDS_DATA:
        return history.push('/perfil/cartoes');
    }
  };

  return (
    <Stack spacing={'48px'} sx={{ alignItems: 'center', minWidth: { xs: '100%', md: '560px' }, paddingTop: '72px', paddingX: { xs: '20px', md: '0px' } }}>
      <Typography fontFamily={'Denton Bold'} color={'#007D34'} fontWeight={700} fontSize={49}>Editar Conta</Typography>
      <Box sx={{ borderColor: 'divider', width: '100%' }}>
        <Tabs
          value={tab}
          onChange={handleChange}
          centered
          variant="fullWidth"
          aria-label="basic tabs example">
          <Tab label="INFORMAÇÕES PESSOAIS" value={TabsEditPersonalData.PERSONAL_DATA} />
          <Tab label="ENDEREÇOS" value={TabsEditPersonalData.ADDRESS_DATA} />
          <Tab label="CARTÕES" value={TabsEditPersonalData.CARDS_DATA} />
        </Tabs>
      </Box>
    </Stack>
  );
};

export default withRouter(ProfileHeader);