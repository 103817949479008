import React, { useState } from 'react';
import { Query } from 'react-apollo';

import ProfileLayout from '../';
import { QUERY_CURRENT_USER } from '../../../graphql/queries/users';
import ProfileAddCardsSection from './ProfileAddCardsSection';
import ProfileCardsSection from './ProfileCardsSection';

const Profile = () => {
  const [showFormCards, setShowFormCards] = useState<boolean>(false);

  return (
    <ProfileLayout>
      <Query key={'ProfilePersonalInfo'} query={QUERY_CURRENT_USER} fetchPolicy='cache-and-network'>
        {
          (result) => [
            <ProfileCardsSection key='profileCardsSection' queryResult={result} showFormCards={showFormCards} setShowFormCards={setShowFormCards} />,
            <ProfileAddCardsSection key='profileAddAddressesSection' queryResult={result} refetch={result.refetch} showFormCards={showFormCards} setShowFormCards={setShowFormCards} />,
          ]
        }
      </Query>
    </ProfileLayout>
  );
}

export default Profile;
