import gql from 'graphql-tag';

import { ICheckoutOrder, IDataOrderDisapproved, IDataProposal, IDetachedPayments, IMyPaymentsOrder } from '../types/checkoutOrders';

export interface IQueryMyOrders {
  myOrders: ICheckoutOrder[];
}

export const QUERY_MY_ORDERS = gql`
  query myOrders(
    $old: Boolean!,
    $page: Int,
    $limit: Int,
    $state: String,
  ) {
    myOrders(
      old: $old,
      page: $page,
      limit: $limit,
      state: $state,
    ) {
      _id
      installments
      shortId
      createdAt
      recurrence
      qrCodePix
      coupon {
        amount
        type
        slug
      }
      amount
      status
      deliveryAddress {
        identifier
        street
        code
        complement
        city
        neighborhood
        state
        number
      }
      subscriptionPayment {
        lastDigitsCard
      }
      lastCard
      checkoutOrderProducts {
        _id
        start
        trackCode
        end
        state
        createdBy {
          upgrade
          preowned
          tradein
          renewed
          crosssell
        }
      stateDates {
        aguardandoPagamento
        pagamentoAprovado
        solicitacaoDeDocumentos
        documentacaoEmAnalise
        documentacaoAprovada
        ordemDePedidoGerada
        ordemDePedidoAprovada
        compraDD
        enderecoConfirmado
        pedidoEnviado
        pedidoEntregue
      }
        product {
          _id
          name
          photos
          description
          technicalDetails
          prices{
            signatureLite
            signatureKeep
            renovationKeep
            resaleKeep
            signatureSwitch
            renovationSwitch
          }
        }
        checkoutOrder
        coupon
        quantity
        amountPaid
      }
      dataPayment {
        payment_method
        link
        expirationPayment
      }
    }
  }
`;

export interface IQueryHasRecurrenceOrder {
  hasRecurrenceOrder: boolean;
}

export const QUERY_HAS_RECURRENCE_ORDER = gql`
  query hasRecurrenceOrder($status: String){
    hasRecurrenceOrder(status: $status)
  }
`;

export interface IQueryCalculateOrderTotalPrice {
  calculateOrderTotalPrice: number;
}

export const QUERY_PROPOSAL_BY_ID = gql`
  query proposalById($proposalId: String) {
    proposalById(proposalId: $proposalId) {
      user {
        email
        personalData {
          firstName
          lastName
        }
      }
      couponId
      vendorId
      plan
      expired
      uniqueProduct
      duration
      productNameGeneric
      availablesStores
      businessHour
      products {
        _id
        name
        prices {
          signatureKeep
          signatureSwitch
          signatureLite
        }
        category {
          _id
          name
        }
        subcategory {
          _id
          name
        }
        technicalDetails
        photos
        delivery
        deadline
      }
    }
  }
`;

export interface IQueryProposalById {
  proposalById: IDataProposal;
}

export const QUERY_CALCULATE_ORDER_TOTAL_PRICE = gql`
  query calculateOrderTotalPrice($items: [CartItem]!, $couponSlug: String, $referral: String, $preownedProduct: Boolean, $couponSlugPaymentMethod: String) {
    calculateOrderTotalPrice(items: $items, couponSlug: $couponSlug, referral: $referral, preownedProduct: $preownedProduct, couponSlugPaymentMethod: $couponSlugPaymentMethod)
  }
`;

export interface IQueryBlockToDisapprovedOrders {
  blockToDisapprovedOrders: IDataOrderDisapproved;
}

export const QUERY_BLOCK_TO_DISAPPROVED_ORDERS = gql`
  query blockToDisapprovedOrders($user: String!, $intervalDaysDisapproved: Int) {
    blockToDisapprovedOrders(
      user: $user,
      intervalDaysDisapproved: $intervalDaysDisapproved,
    ){
      block
      daysWait
    }
  }
`;

export interface IQueryOrderByShortId {
  searchOrderById: ICheckoutOrder;
}


export const QUERY_ORDER_BY_SHORTID = gql`
  query searchOrderById($shortId: String!){
    searchOrderById(
      shortId: $shortId,
    ){ 
      _id
      installments
      shortId
      createdAt
      recurrence
      amount
      realTotal
      coupon {
        amount
        type
        slug
        flags{
          memberGetMember
          disabled
        }
      }
      amount
      status
      subscriptionPayment{
        monthValue
        limitMonthDiscount
        byTotodile
        entryFeeValue
      }
      deliveryAddress {
        street
        code
        complement
        city
        neighborhood
        state
        number
      }
      checkoutOrderProducts {
        _id
        createdBy {
          upgrade
          preowned
          tradein
          renewed
          crosssell
        }
        start
        end
        state
        product {
          _id
          name
          photos
          description
          technicalDetails
          prices{
            signatureLite
            signatureKeep
            renovationKeep
            resaleKeep
            signatureSwitch
            renovationSwitch
          }
        }
        checkoutOrder
        coupon
        quantity
        amountPaid
      }
      alreadyPaymentPaid
    }
  }
`;

export interface IQueryAllPaymentsByOrderId {
  allPaymentsByOrderId: IMyPaymentsOrder;
};

export const QUERY_ALL_PAYMENTS_ORDER = gql`
  query allPaymentsByOrderId($id: String){
    allPaymentsByOrderId(id: $id){
      user {
        email
        document
        firstName
        lastName
      }
      order{
        _id
        shortId
        recurrence
        amount
        realTotal
        status
        subscriptionPayment{
          monthValue
          limitMonthDiscount
          byTotodile
          entryFeeValue
        }
        checkoutOrderProducts {
          _id
          createdBy {
            upgrade
            preowned
            tradein
            renewed
            crosssell
          }
          start
          end
          state
          product {
            _id
            name
            photos
            description
            prices{
              signatureLite
              signatureKeep
              renovationKeep
              resaleKeep
              signatureSwitch
              renovationSwitch
            }
        }
        checkoutOrder
      }
    }
      payments {
        index
        start
        end
        expectedPayment
        payment {
          type
          referenceValue
          data {
            _id
            checkoutOrderId
            updatedAt
            payload {
              payment_method
              amount
              installments
              link
              amountInterest
            }
            result {
              datePaid
              authorization_code
              transaction_id
              status
              paid_amount
              risk
              card {
                card_owner
                card_number
                card_brand
              }
            }
          }
        }
      }
    }
  }
`;
export interface IQueryDetachedPaymentsByOrderId {
  detachedPaymentsByOrderId: IDetachedPayments;
};

export const QUERY_DETACHED_PAYMENTS_ORDER = gql`
  query detachedPaymentsByOrderId($id: String){
    detachedPaymentsByOrderId(id: $id){
      order{
        _id
        shortId
        recurrence
        amount
        subscriptionPayment{
          monthValue
          limitMonthDiscount
          byTotodile
          entryFeeValue
        }
      }
      detachedPayment {
        _id
        checkoutOrderId
        updatedAt
        payload {
          payment_method
          amount
          installments
          link
          amountInterest
        }
        result {
          datePaid
          authorization_code
          transaction_id
          status
          paid_amount
          risk
          card {
            card_owner
            card_number
            card_brand
          }
        }
      }
    }
  }
`;