import gql from 'graphql-tag';
import { ICreatedOrUpdateUser, IPublicUser } from '../types/users';

export interface IMutationUserLogin {
  login: string;
}

export const MUTATION_USER_LOGIN = gql`
  mutation login(
    $email: String!,
    $password: String!,
    $captchaToken: String!
  ) {
    login(
      email: $email,
      password: $password,
      captchaToken: $captchaToken
    )
  }
`;

export interface IMutationEditMe {
  editMe: IPublicUser;
}

export const MUTATION_EDIT_ME = gql`
  mutation editMe(
    $firstName: String,
    $lastName: String,
    $phone: String,
    $birthday: Date,
    $document: String,
    $gender: UserGender,
  ) {
    editMe(
      firstName: $firstName,
      lastName: $lastName,
      phone: $phone,
      birthday: $birthday,
      document: $document,
      gender: $gender,
    ) {
      personalData {
        firstName
        lastName
        birthday
        gender
      }
      phone
    }
  }
`;

export interface IMutationChangeMyPassword {
  changeMyPassword: boolean;
}

export const MUTATION_CHANGE_MY_PASSWORD = gql`
  mutation changeMyPassword(
    $oldPassword: String!,
    $newPassword: String!,
  ) {
    changeMyPassword(
      oldPassword: $oldPassword,
      newPassword: $newPassword,
    )
  }
`;

export interface IMutationUploadPersonDocuments {
  uploadPersonDocuments: boolean;
}

export const MUTATION_UPLOAD_PERSON_DOCS = gql`
  mutation uploadPersonDocuments(
    $idFront: String,
    $idBack: String,
    $docType: String,
    $selfie: String,
    $residence: String,
    $answerDiffAddresses: String,
    $incomeVoucher: String,
    $docId: String,
  ) {
    uploadPersonDocuments(
      idFront: $idFront,
      idBack: $idBack,
      docType: $docType,
      selfie: $selfie,
      residence: $residence,
      answerDiffAddresses: $answerDiffAddresses,
      incomeVoucher: $incomeVoucher,
      docId: $docId,
    )
  }
`;

export interface IMutationUploadDocsPersonCard {
  uploadDocsPersonCard: boolean;
}

export const MUTATION_UPLOAD_DOCS_CARD_PERSON = gql`
  mutation uploadDocsPersonCard(
    $docTypePersonCard: String,
    $docFrontPersonCard: String,
    $docBackPersonCard: String,
    $selfiePersonCard: String,
  ) {
    uploadDocsPersonCard(
      docTypePersonCard: $docTypePersonCard,
      docFrontPersonCard: $docFrontPersonCard,
      docBackPersonCard: $docBackPersonCard,
      selfiePersonCard: $selfiePersonCard,
    )
  }
`;

export interface IMutationUploadDocsResidence {
  uploadDocsResidence: boolean;
}

export const MUTATION_UPLOAD_DOCS_RESIDENCE = gql`
  mutation uploadDocsResidence(
    $residence: String,
    $answerDiffAddresses: String,
    $docTypePersonResidence: String,
    $docFrontPersonResidence: String,
    $docBackPersonResidence: String,
    $samePersonInDocCardAndDocResidence: Boolean,
  ) {
    uploadDocsResidence(
      residence: $residence,
      answerDiffAddresses: $answerDiffAddresses,
      docTypePersonResidence: $docTypePersonResidence,
      docFrontPersonResidence: $docFrontPersonResidence,
      docBackPersonResidence: $docBackPersonResidence,
      samePersonInDocCardAndDocResidence: $samePersonInDocCardAndDocResidence,
    )
  }
`;

export interface IMutationReuploadDocuments {
  reUploadDocuments: boolean;
}

export const MUTATION_REUPLOAD_DOCUMENTS = gql`
  mutation reUploadDocuments(
    $docType: String,
    $idFront: String,
    $idBack: String,
    $selfie: String,
    $incomeVoucher: String,
    $residence: String,
    $docTypePersonResidence: String,
    $docFrontPersonResidence: String,
    $docBackPersonResidence: String,
    $docTypePersonCard: String,
    $docFrontPersonCard: String,
    $docBackPersonCard: String,
    $selfiePersonCard: String,
    $others: [String],
    $docId: String,
  ) {
    reUploadDocuments(
      docType: $docType,
      idFront: $idFront,
      idBack: $idBack,
      selfie: $selfie,
      incomeVoucher: $incomeVoucher,
      residence: $residence,
      docTypePersonResidence: $docTypePersonResidence,
      docFrontPersonResidence: $docFrontPersonResidence,
      docBackPersonResidence: $docBackPersonResidence,
      docTypePersonCard: $docTypePersonCard,
      docFrontPersonCard: $docFrontPersonCard,
      docBackPersonCard: $docBackPersonCard,
      selfiePersonCard: $selfiePersonCard,
      others: $others,
      docId: $docId,
    )
  }
`;

export interface IMutationEditDocuments {
  editDocuments: boolean;
}

export const MUTATION_EDIT_DOCUMENTS = gql`
  mutation editDocuments(
    $idFront: String,
    $idBack: String,
    $selfie: String,
    $residence: String,
    $others: [String]
  ) {
    editDocuments(
      idFront: $idFront,
      idBack: $idBack,
      selfie: $selfie,
      residence: $residence,
      others: $others,
    )
  }
`;

export interface IMutationAddAddress {
  addAddress: boolean;
}

export const MUTATION_ADD_ADDRESS = gql`
  mutation addAddress(
    $identifier: String!,
    $code: String!,
    $state: String!,
    $city: String!,
    $neighborhood: String!,
    $street: String!,
    $number: String!,
    $complement: String,
    $lat: Float!,
    $lng: Float!,
    $placeId: String!,
  ) {
    addAddress(
      identifier: $identifier,
      code: $code,
      state: $state,
      city: $city,
      neighborhood: $neighborhood,
      street: $street,
      number: $number,
      complement: $complement,
      lat: $lat,
      lng: $lng,
      placeId: $placeId,
    )
  }
`;

export interface IMutationRemoveCardPagarme {
  removeCardPagarme: boolean;
}

export const MUTATION_REMOVE_CARD_PAGARME = gql`
  mutation removeCardPagarme($cardId: String!) {
    removeCardPagarme(cardId: $cardId)
  }
`;

export interface IMutationAddCardPagarme {
  addCardPagarme: boolean;
}

export const MUTATION_ADD_CARD_PAGARME = gql`
  mutation addCardPagarme($token: String!) {
    addCardPagarme(token: $token)
  }
`;

export interface IMutationRemoveCardMP {
  removeCardMP: boolean;
}

export const MUTATION_REMOVE_CARD_MERCADO_PAGO = gql`
  mutation removeCardMP($cardId: String!) {
    removeCardMP(cardId: $cardId)
  }
`;

export interface IMutationAddCardMP {
  addCardMP: boolean;
}

export const MUTATION_ADD_CARD_MERCADO_PAGO = gql`
  mutation addCardMP($token: String!) {
    addCardMP(token: $token)
  }
`;

export interface IMutationSetDefaultCardMP {
  setDefaultCardMP: boolean;
}

export const MUTATION_SET_DEFAULT_CARD = gql`
  mutation setDefaultCardMP($cardId: String!) {
    setDefaultCardMP(cardId: $cardId)
  }
`;

export interface IMutationEditAddress {
  editAddress: boolean;
}

export const MUTATION_EDIT_ADDRESS = gql`
  mutation editAddress(
    $identifier: String,
    $code: String,
    $state: String,
    $city: String,
    $neighborhood: String,
    $street: String,
    $number: String,
    $complement: String,
    $lat: Float,
    $lng: Float,
    $placeId: String,
    ) {
      editAddress(
        identifier: $identifier,
        code: $code,
        state: $state,
        city: $city,
        neighborhood: $neighborhood,
        street: $street,
        number: $number,
        complement: $complement,
        lat: $lat,
        lng: $lng,
        placeId: $placeId,
    )
  }
`;

export interface IMutationRemoveAddress {
  removeAddress: boolean;
}

export const MUTATION_REMOVE_ADDRESS = gql`
  mutation removeAddress($identifier: String!) {
    removeAddress(identifier: $identifier)
  }
`;

export interface IMutationRequestNewPassword {
  requestNewPassword: boolean;
}

export const MUTATION_REQUEST_NEW_PASSWORD = gql`
  mutation requestNewPassword($email: String!) {
    requestNewPassword(email: $email)
  }
`;

export interface IMutationResetPassword {
  resetPassword: string;
}

export const MUTATION_RESET_PASSWORD = gql`
  mutation resetPassword(
    $userId: String!,
    $newPasswordToken: String!,
    $newPassword: String!,
  ) {
    resetPassword(
      userId: $userId,
      newPasswordToken: $newPasswordToken,
      newPassword: $newPassword,
    )
  }
`;

export interface IMutationSaveCart {
  saveCart: boolean;
}

export const MUTATION_SAVE_CART = gql`
  mutation saveCart(
    $items: [CartItemType],
    $selectedAddress: Int,
    $coupon: String,
    $goPhone: Boolean,
  ) {
    saveCart(
      items: $items,
      selectedAddress: $selectedAddress,
      coupon: $coupon,
      goPhone: $goPhone,
    )
  }
`;

export interface IMutationFranchiseQueue {
  franchiseQueue: boolean;
}

export const MUTATION_FRANCHISE_QUEUE = gql`
  mutation franchiseQueue(
    $name: String!,
    $phone: String!,
    $email: String!,
    $amount: Int!,
    $franchise: String!
    $source: String,
  ) {
    franchiseQueue(
      name: $name,
      phone: $phone,
      email: $email,
      amount: $amount,
      franchise: $franchise,
      source: $source,
    )
  }
`;

export interface IMutationSendPhoneNumberVerificationCode {
  sendPhoneNumberVerificationCode: boolean;
}

export const MUTATION_SEND_PHONE_NUMBER_VERIFICATION_CODE = gql`
  mutation sendPhoneNumberVerificationCode {
    sendPhoneNumberVerificationCode
  }
`;

export interface IMutationVerifyPhoneNumber {
  verifyPhoneNumber: boolean;
}

export const MUTATION_VERIFY_PHONE_NUMBER = gql`
  mutation verifyPhoneNumber($code: String!) {
    verifyPhoneNumber(code: $code)
  }
`;

export interface IMutationEndOfSession {
  endOfSession: boolean;
}

export const MUTATION_END_OF_SESSION = gql`
  mutation endOfSession($cartItems: Int!) {
    endOfSession(cartItems: $cartItems)
  }
`;

export interface IMutationCreateOrUpdate {
  createOrUpdate: ICreatedOrUpdateUser;
}


export const MUTATION_CREATE_OR_UPDATE = gql`
  mutation createOrUpdate(
    $firstName: String!, 
    $lastName: String!, 
    $email: String!, 
    $document: String!, 
    $phone: String!, 
    $birthday: Date!) {
    createOrUpdate(
      firstName: $firstName,
      lastName: $lastName,
      email: $email,
      document: $document,
      phone: $phone,
      birthday: $birthday,
      ){
        action,
        token
      }
  }
`;