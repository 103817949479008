import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button, Link, Stack, Typography } from '@mui/material';
import Address from '../../../components/Address/form';
import { IUserAddress } from '../../../graphql/types/users';
import SummaryAddress from '../../../components/Address/summary';
import { validatorAddressSchema } from '../../../validators/validatorAddress';
import { toast } from 'react-toastify';
import { validateDeliveryAddressStepCheckout } from '../../../helpers/validateDataUser';

const initialValues: IUserAddress = {
  city: '',
  code: '',
  street: '',
  complement: '',
  neighborhood: '',
  number: '',
  state: '',
};
interface IProps {
  deliveredAddress: IUserAddress;
  setDeliveredAddress: (address: IUserAddress) => void;
}

const BillingAddressForm: React.FC<IProps> = ({ deliveredAddress, setDeliveredAddress }: IProps) => {

  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    if (deliveredAddress && !validateDeliveryAddressStepCheckout(deliveredAddress)) {
      setExpanded(true);
    }
  }, [deliveredAddress])

  const submit = async (values: IUserAddress) => {
    setDeliveredAddress(values);
    setExpanded(false);
  };

  const cancel = () => {
    if (!validateDeliveryAddressStepCheckout(deliveredAddress)) {
      toast.error('Necessário preencher todos os campos do endereço.');
      return;
    }
    setExpanded(false);
  };

  const editForm = () => {
    setExpanded(true);
  };

  const renderAddress = () => {
    if (!expanded && deliveredAddress)
      return <SummaryAddress {...deliveredAddress} />;
    else if (expanded) {
      return (
        <Formik
          initialValues={deliveredAddress || initialValues}
          onSubmit={(values: IUserAddress) => submit(values)}
          validationSchema={validatorAddressSchema}
        >
          {(formikProps) => (
            <Stack spacing={'24px'}>
              <Address {...formikProps} />
              <Stack direction="row" spacing={'24px'} sx={{ width: '100%' }}>
                {deliveredAddress && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="large"
                    sx={{ width: '100%' }}
                    onClick={() => cancel()}
                  >
                    CANCELAR
                  </Button>
                )}
                <Button
                  variant="contained"
                  size="large"
                  sx={{ width: '100%' }}
                  onClick={() => formikProps.handleSubmit()}
                >
                  SALVAR
                </Button>
              </Stack>
            </Stack>
          )}
        </Formik>
      );
    }
    return;
  };


  return (
    <Stack spacing={'24px'}>
      <Stack
        direction="row"
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography variant="h6" fontSize={21}>
          Endereço de Entrega
        </Typography>
        {deliveredAddress && (
          <Link
            color={'#000000'}
            onClick={() => editForm()}
            fontSize={13}
            sx={{
              cursor: 'pointer'
            }}>
            Editar
          </Link>
        )}
      </Stack>
      {renderAddress()}
    </Stack>
  );
};


export default BillingAddressForm;