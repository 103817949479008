import { IPublicCoupon } from './coupons';
import { IProduct } from './products';
import { IDataUser, IUserAddress } from './users';

export enum CheckoutOrderStatus {
  WAITING = 'waiting',
  APPROVED = 'approved',
  ON_THE_WAY = 'on_the_way',
  CANCELED = 'canceled',
  GENERATED_PIX = 'generated_pix',
  GENERATED_LINK = 'link_gerado',
}

export enum State {
  cancelado = 'Cancelado',
  pagamentoAprovado = 'Pagamento Aprovado',
  solicitacaoDeDocumentos = 'Solicitação de Documentos',
  novaSolicitacaoDeDocumentos = 'Nova Solicitação de Documentos',
  documentacaoEmAnalise = 'Documentação em Análise',
  documentacaoAprovada = 'Documentação Aprovada',
  ordemDePedidoGerada = 'Ordem de Pedido Gerada',
  ordemDePedidoAprovada = 'Ordem de Pedido Aprovada',
  compraDD = 'Compra DD',
  enderecoConfirmado = 'Endereço Confirmado',
  pedidoEnviado = 'Pedido Enviado',
  pedidoEntregue = 'Pedido Entregue',
  devolucaoAtrasada = 'Devolução Atrasada',
  produtoDevolvido = 'Produto Devolvido',
  assinaturaRenovada = 'Assinatura Renovada',
  recuperado = 'Recuperado',
  naoRecuperado = 'Não Recuperado',
  aguardandoPagamento = 'Aguardando Pagamento',
  emRecuperacao = 'Em Recuperação'
}

export enum Plan {
  KEEP = 'keep',
  SWITCH = 'switch',
  LITE = 'lite',
}

export interface IInvoice {
  amount: number;
  user: string;
}

interface IStateDates {
  pagamentoAprovado?: Date;
  solicitacaoDeDocumentos?: Date;
  documentacaoEmAnalise?: Date;
  documentacaoAprovada?: Date;
  ordemDePedidoGerada?: Date;
  ordemDePedidoAprovada?: Date;
  compraDD?: Date;
  enderecoConfirmado?: Date;
  pedidoEnviado?: Date;
  pedidoEntregue?: Date;
  aguardandoPagamento?: Date;
}

interface IPayment {
  link: string;
  payment_method: string;
  expirationPayment: Date;
}

export interface ISubscriptionPayment {
  monthValue: number;
  limitMonthDiscount?: number;
  lastDigitsCard?: string;
  byTotodile?: boolean;
  entryFeeValue?: number;
}

interface ICreatedBy {
  preowned: boolean;
  tradein: boolean;
  renewed: boolean;
  upgrade: boolean;
  crosssell: boolean;
}
export interface ICheckoutOrderProduct {
  _id: string;
  start: Date;
  end: Date;
  product: IProduct;
  checkoutOrder: string;
  quantity: number;
  coupon?: string;
  state?: string;
  stateDates?: IStateDates;
  amountPaid?: number;
  createdBy: ICreatedBy;
  trackCode?: string;
}

export enum Origin {
  ADMIN = 'admin',
  SITE = 'site',
  PARTNER = 'partner'
}

export interface ICheckoutOrder {
  _id: string;
  shortId?: string;
  user: string;
  amount: number;
  realTotal: number;
  status: CheckoutOrderStatus;
  deliveryAddress?: IUserAddress;
  checkoutOrderProducts: ICheckoutOrderProduct[];
  coupon?: IPublicCoupon;
  installments: number;
  createdAt: string;
  dataPayment?: IPayment;
  recurrence?: boolean;
  subscriptionPayment: ISubscriptionPayment;
  lastCard?: string;
  alreadyPaymentPaid?: boolean;
  qrCodePix?: string;
  origin: Origin;
}

export interface IDataOrderDisapproved {
  block: boolean;
  daysWait: number;
}

export interface IPaymentTwoCardResult {
  paid: boolean;
  orderId: string;
  secondValue: number;
  transactionId: string;
  shortId?: string;
}

export interface IPaymentBoletoResult {
  orderId: string;
  boletoUrl: string;
}
export interface IPaymentPixResult {
  orderId: string;
  qrCode: string;
  shortId: string;
}
export interface IDataProposal {
  user: IDataUser;
  couponId?: string;
  vendorId?: string;
  plan: string;
  expired: boolean;
  uniqueProduct?: boolean;
  duration: number;
  products: IProduct[];
  availablesStores: string[];
  productNameGeneric: string;
  businessHour: boolean;
}

interface Card {
  card_owner?: string;
  card_number?: string;
  card_brand?: string;
};

export interface PagarmeLog {
  _id: string;
  checkoutOrderId: string;
  updatedAt: Date;
  result?: {
    transaction_id: string;
    authorization_code: string;
    status: string;
    paid_amount: string;
    risk?: string;
    card?: Card;
    datePaid?: Date;
    dateRefund?: Date;
    interest?: number;
  };
  payload: {
    payment_method: string;
    amount: string;
    installments: string;
    link?: string;
    expirationPayment?: Date;
    amountInterest?: number;
  };
};

interface IPaymentMyRecurrence {
  data?: PagarmeLog;
  type?: string;
  referenceValue: number;
}
export interface IExpectedPayments {
  index: number;
  start?: Date;
  end?: Date;
  expectedPayment: boolean;
  payment?: IPaymentMyRecurrence;
}

export interface IUserDataHidden {
  email: string;
  document: string;
  firstName: string;
  lastName: string;
}

export interface IMyPaymentsOrder {
  user: IUserDataHidden;
  order: ICheckoutOrder;
  payments: IExpectedPayments[];
}

export interface IDetachedPayments {
  order: ICheckoutOrder;
  detachedPayment: PagarmeLog[];
}