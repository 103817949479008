import React from 'react';
import { Stack, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import { IFormikPersonalDataRecurrenceFields } from '../index';
import TextFieldMask from '../../../../components/Inputs/InputMask';

interface IProps extends FormikProps<IFormikPersonalDataRecurrenceFields> {
  submitByEnter?: () => void;
}

const PersonalDataForm = ({
  handleBlur,
  handleChange,
  values,
  touched,
  errors,
  submitByEnter,
}: IProps) => {
  return (
    <Stack spacing={'20px'}>
      <Typography variant="h6">Dados Pessoais</Typography>

      <TextFieldMask
        mask="999.999.999-99"
        label="CPF"
        type="text"
        name={"document"}
        onBlur={handleBlur('document')}
        setValue={handleChange('document')}
        value={values.document}
        touched={touched.document}
        errorMessage={errors.document}
        disabled={false}
        submitByEnter={submitByEnter}
        />
      <TextFieldMask
        mask="(99)99999-9999"
        label="Celular"
        type="text"
        name={"phone"}
        onBlur={handleBlur('phone')}
        setValue={handleChange('phone')}
        value={values.phone}
        touched={touched.phone}
        errorMessage={errors.phone}
        isRequired={true}
        submitByEnter={submitByEnter}
      />
    </Stack>
  );
};

export default PersonalDataForm;
