import { HelpOutline } from '@mui/icons-material';
import { Box, Icon, Stack, Tooltip } from '@mui/material';
import { FormikProps } from 'formik';
import React, { ReactNode } from 'react';
import { ICreditCard } from '../../actionReducers/Checkout';
import { ICreditCardRecurrence } from '../../actionReducers/Recurrence';
import TextFieldMask from '../Inputs/InputMask';
import InputText from '../Inputs/InputText';

interface IProps extends FormikProps<ICreditCardRecurrence> {
    children?: ReactNode;
    creditCard?: ICreditCard;
    handleBlurFields?: (field: string, value: string | number, errors: any) => void;
    handleChangeCvv?: (value: string, errors: any) => void;
}

const FormCard = ({
    children,
    touched,
    errors,
    values,
    handleBlur,
    handleChange,
    handleBlurFields,
    handleChangeCvv
}: IProps) => {
    return (
        <Box>
            <Stack spacing={'16px'} sx={{ padding: '32px 0px' }}>
                <TextFieldMask
                    mask={'9999 9999 9999 9999'}
                    label="Número do Cartão"
                    type="text"
                    name={"number"}
                    onBlur={(e) => { handleBlur('number')(e), handleBlurFields('number', values.number, errors) }}
                    setValue={handleChange('number')}
                    value={values.number}
                    touched={touched.number}
                    errorMessage={errors.number}
                    isRequired={true}
                />
                <InputText
                    label="Nome - Como no cartão"
                    type="text"
                    name={"holderName"}
                    onBlur={(e) => { handleBlur('holderName')(e), handleBlurFields('holderName', values.holderName, errors) }}
                    setValue={handleChange('holderName')}
                    value={values.holderName}
                    touched={touched.holderName}
                    errorMessage={errors.holderName}
                    isRequired={true}
                    replace={/[0-9.]+/g}
                />
                <Stack
                    direction={'row'}
                    spacing={'16px'}
                    alignItems="center"
                >
                    <TextFieldMask
                        mask="99/99"
                        name={"expirationDate"}
                        label="Validade"
                        type="text"
                        style={{ width: '50%' }}
                        onBlur={(e) => { handleBlur('expirationDate')(e), handleBlurFields('expirationDate', values.expirationDate, errors) }}
                        setValue={handleChange('expirationDate')}
                        value={values.expirationDate}
                        touched={touched.expirationDate}
                        errorMessage={errors.expirationDate}
                        isRequired={true}
                    />
                    <InputText
                        label="CVV"
                        name={"cvv"}
                        type="text"
                        style={{ width: '50%' }}
                        onBlur={(e) => { handleBlur('cvv')(e), handleBlurFields('cvv', values.cvv, errors) }}
                        setValue={(e) => { handleChange('cvv')(e), handleChangeCvv(e, errors) }}
                        value={values.cvv}
                        touched={touched.cvv}
                        errorMessage={errors.cvv}
                        isRequired={true}
                        maxLengthInput={4}
                        replace={/[^0-9.]+/g}
                        inputIcon={
                            <Tooltip title={'O código de segurança poder ter 3 ou 4 dígitos.'}>
                                <Icon color={'action'}>
                                    <HelpOutline />
                                </Icon>
                            </Tooltip>
                        }
                    />
                </Stack>
                {children}
            </Stack>
        </Box>
    )
};

export default FormCard;