import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { QueryResult } from 'react-apollo';

import { IQueryCurrentUser } from '../../../graphql/queries/users';
import { IUserAddress } from '../../../graphql/types/users';
import ProfileAddress from './ProfileAddress';
import { Add } from '@mui/icons-material';

interface IProfileAddresses {
  queryResult: QueryResult<IQueryCurrentUser>;
  showFormAddress: boolean;
  setShowFormAddress: Dispatch<SetStateAction<boolean>>;
}

const ProfileAddressSection = ({ queryResult, showFormAddress, setShowFormAddress }: IProfileAddresses) => {

  const renderAddresses = (address: IUserAddress) => {
    return <ProfileAddress key={address.identifier} address={address} refetch={queryResult.refetch} setShowFormAddress={setShowFormAddress} />
  }

  const { loading, data } = queryResult;

  let addresses = null;

  if (!loading && data) {
    addresses = data.currentUser.addresses.map(renderAddresses);
  }

  return (
    <>
      {
        loading ? (
          <Stack sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: "center", padding: "30px" }}>
            <CircularProgress sx={{ color: '#27AE60' }} size={'40px'} />
          </Stack>
        ) : (
          <Box>
            <Typography fontFamily={'Chillax'} fontWeight={500} fontSize={'21px'} sx={{ color: '#27AE60', padding: '48px 0 24px' }}>Meus endereços</Typography>
            {
              !addresses.length ? (
                <Typography fontFamily={'Chillax'} fontWeight={400} fontSize={'16px'} >Você ainda não tem um endereço cadastro.</Typography>

              ) : (
                <Stack spacing={'24px'}>
                  {addresses}
                </Stack>
              )
            }
            {
              !showFormAddress ? (
                <Box sx={{ padding: '48px 0 72px' }}>
                  <Button variant='outlined' sx={{ color: '#27AE60' }} startIcon={<Add />} onClick={() => setShowFormAddress(true)}>ADICIONAR ENDEREÇO</Button>
                </Box>
              ) : null
            }
          </Box>
        )
      }
    </>
  );
}

export default ProfileAddressSection;
