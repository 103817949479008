import { Form } from 'formik';
import { GeosuggestProps } from 'react-geosuggest';
import styled from 'styled-components';
import GeosuggestInput from '../../../components/GeosuggestInput';
import { CommonDiv, SuccessButton } from '../../../components/styled';
import { media, sizes } from '../../../helpers/style';
import { gray11, gray2, gray3, gray4, gray5, gray6, green1, green2, white } from '../../../style/colors';

const Container = styled.div`
  max-width: 1750px;
  width: 100%;
  margin: 0 auto;
  padding: 12px calc(5% - 2px) 0px;

  @media (max-width: 1440px) {
    max-width: 1300px;
  }

  @media (max-width: 1280px) {
    max-width: 1150px;
  }

  @media (max-width: 640px) {
    max-width: 1150px;
    padding: 12px calc(3% - 2px) 0px;
  }
`;

const OrdersWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  min-height: calc(90vh - 180px);
  justify-content: flex-start;
  align-items: flex-start;
  max-width: ${sizes.medium}px;

  ${media.small`
      max-width: unset;
    `
  }
`;

const NextOrdersHeader = styled.h1`
  font-size: 22px;
  font-weight: 700;
  color: ${gray11};
  margin: 18px 0;
`;

const OldOrdersHeader = styled.h2`
  font-size: 22px;
  font-weight: 700;
  color: ${gray11};
  margin: 24px 0 18px;
`;

const OrderProductCard = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  background-color: ${white};
  box-shadow: 0px 0px 2px 1px ${gray2};
  width: calc(100% - 20px);
  padding: 10px;
  margin: 20px 0 12px;
  border-radius: 4px;
  align-items: center;
  font-size: 14px;
  color: ${gray11};

  img {
    width: 70px;
    height: 70px;
  }
`;

const OrderProductCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  flex-grow: 1;

  strong {
    font-weight: 600;
  }

  span {
    font-size: 12px;
    color: ${gray6};
  }
`;

const OrderProductCardPrice = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 500;
  align-items: center;

  button {
    color: ${green1};
    font-weight: 500;
    margin-bottom: 12px;
  }
`;

const OrderProductCardDates = styled.div`
  position: absolute;
  font-size: 12px;
  color: ${gray6};
  top: -20px;
  left: 0;
`;

const MoreInfoModal = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 5;
  background-color: ${white};
  font-size: 14px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  z-index: 999;
  padding: 40px 20px;
  overflow: auto;
  width: calc(60vw - 40px);
  top: 2vh;
  left: 20vw;

  ${media.small`
      top: 10vh;
      left: 4vw;
      width: calc(92vw);
    `
  }
`;

const Information = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: ${gray11};
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 4px 0;
  
  span {
    color: ${green1};
  }
  
  div {
    font-weight: 500;
    display: flex;
    flex-grow: 1;
    margin: 4px 0 10px;
  }
`;

const PaymentInformation = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${gray11};
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
 
  div {
    margin-left: 10px;
    ${media.small`
      font-size: 12px;
      margin-left: 4px;
    `}
  }
`;

const DeliveryInformation = styled.div`
  display: flex;
  font-size: 14px;
  flex-direction: column;
  margin: 12px 0 4px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  strong {
    font-weight: 700;
  }

  p {
    margin: 4px 0;
    font-weight: 500;
  }
`;

const GreenStatusInformation = styled.div`
  display: flex;
  font-size: 14px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  color: green;
  p {
    font-weight: 700;
  }

  ${media.small`
      font-size: 12px;
    `
  }

  @media (max-width: 344px) {
    font-size: 10px;
  }

  @media (max-width: 295px) {
    font-size: 9px;
  }
`;

const GrayStatusInformation = styled.div`
  display: flex;
  font-size: 14px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  color: gray;
  p {
    font-weight: 700;
  }

  ${media.small`
      font-size: 12px;
    `
  }

  @media (max-width: 344px) {
    font-size: 10px;
  }

  @media (max-width: 295px) {
    font-size: 9px;
  }
`;

const StatusContainer = styled.div`
  width: 100%;
  display: flex;
`;

const UseTerms = styled.p`
  width: 100%;
  display: flex;
  font-size: 14px;
`;

const StateDate = styled.div<{ increaseSpace?: boolean }>`
  display: flex;
  font-size: 14px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: ${gray11};
  p {
    font-weight: 700;
  }
  ${(props) => props.increaseSpace
    ?
    `${`${media.medium`
        font-size: 14px;
      `}
      ${media.small`
        font-size: 12px;
      `}`
    }`
    :
    ` ${media.small`
        font-size: 12px;
      `
    }`
  }
  @media (max-width: 344px) {
    font-size: 10px;
  }
`;
const TimeLineContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 50px;
  padding: 11px 0 11px;
  margin: 3px 0 5px;
`;

const GreenTimeLine = styled.div<{ increaseLine?: boolean }>`
  height: 100%;
  width: 4px;
  margin-left: 23px;
  background: green;
  @media (max-width: 295px) {
    width: 3px;
  }
`;

const GrayTimeLine = styled.div<{ increaseLine?: boolean }>`
  height: 100%;
  width: 4px;
  margin-left: 23px;
  background: gray;
  @media (max-width: 295px) {
    width: 3px;
  }
`;

const GreenTimeLineCircle = styled.div`
  background: green;
  margin-left: 20px;
  width: 10px;
  min-height: 10px;
  border-radius: 50%;
  
  @media (max-width: 344px) {
    width: 8px;
    min-height: 8px;
    margin-left: 21px;
  }
  @media (max-width: 295px) {
    width: 7px;
    min-height: 7px;
  }
  `;

const GrayTimeLineCircle = styled.div`
  background: gray;
  margin-left: 20px;
  width: 10px;
  min-height: 10px;
  border-radius: 50%;
  @media (max-width: 344px) {
    width: 8px;
    min-height: 8px;
    margin-left: 21px;
  }
  @media (max-width: 295px) {
    width: 7px;
    min-height: 7px;
  }
`;

const DeliveryButton = styled(SuccessButton)`
  font-size: 14px;
  border-radius: 4px;
  margin: 12px 0;
`;

const ProductItem = styled.div`
  width: 100%;
  display: flex;
  padding: 6px;
  font-size: 14px;
  color: ${gray11};
  border-top: 1px dashed ${gray6};
  margin-top: 6px;
  flex-direction: column;
  font-weight: 500;

  div {
    width: 100%;
    font-size: 12px;
    margin-top: 2px;
  }
`;

const AddDocumentsButton = styled.label`
  cursor: pointer;
  text-align: center;
  width: 170px;
  height: 14px;
  border-radius: 3em;
  border: none;
  color: white;
  background-color: ${green1};
  transition: background-color 0.2s;
  font-size: 11px;
  font-weight: 600;
  padding: 2px 7px 2px 7px;
  &:hover {
    background-color: ${green2};
  }
  ${media.small`
    height: 14px;
    width: 100px;
    font-size: 10px;
    font-weight: 20px;
    top: 0;
    position: relative;
  `}
`;

const ViewerBilletButton = styled.label`
  cursor: pointer;
  text-align: center;
  width: 170px;
  height: 14px;
  border-radius: 3em;
  border: none;
  color: white;
  background-color: ${green1};
  transition: background-color 0.2s;
  font-size: 11px;
  font-weight: 600;
  padding: 2px 7px 2px 7px;
  &:hover {
    background-color: ${green2};
  }
  ${media.medium`
      width: 90px;
      position: relative;
    `
  }
  ${media.small`
      height: 14px;
      width: 100px;
      font-size: 10px;
      font-weight: 20px;
      top: 0;
      position: relative;
    `
  }
`;

const ChangeCardButton = styled.label`
  cursor: pointer;
  text-align: center;
  width: 200px;
  margin-left: 15px;
  height: 17px;
  border-radius: 0.3em;
  border: none;
  color: white;
  background-color: ${green1};
  transition: background-color 0.2s;
  font-size: 14px;
  font-weight: 500;
  padding: 2px 5px 2px 5px; 
  &:hover {
    background-color: ${green2};
  }
  ${media.medium`
      width: 90px;
      position: relative;
    `
  }
  ${media.small`
      height: 14px;
      width: 100px;
      font-size: 12px;
      font-weight: 20px;
      top: 0;
      position: relative;
      margin-left: 4px;
    `
  }
`;

const ViewQRCodePixButton = styled.label`
  cursor: pointer;
  text-align: center;
  width: 200px;
  margin-left: 15px;
  height: 17px;
  border-radius: 0.3em;
  border: none;
  color: white;
  background-color: ${green1};
  transition: background-color 0.2s;
  font-size: 14px;
  font-weight: 500;
  padding: 2px 5px 2px 5px; 
  &:hover {
    background-color: ${green2};
  }
  ${media.medium`
      width: 90px;
      position: relative;
    `
  }
  ${media.small`
      height: 14px;
      width: 100px;
      font-size: 12px;
      font-weight: 20px;
      top: 0;
      position: relative;
      margin-left: 4px;
    `
  }
`;

const AddressBox = styled.button`
  width: 100%;
  padding: 6px 12px;
  border: 1px solid ${gray4};
  font-size: 14px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
  background-color: ${(props: { selected?: boolean }) =>
    props.selected
      ? green1
      : 'transparent'
  };
  color: ${(props: { selected?: boolean }) =>
    props.selected
      ? white
      : gray11
  };
  outline: none;

  div:first-child {
    font-weight: 500;
  }

  div {
    display: flex;
    width: 100%;
    margin: 4px 0;
    align-items: center;
    margin-left: 6px;
    text-align: left;
  }
`;

const AddressGeosuggestInput = styled<GeosuggestProps>(GeosuggestInput)`
  .geosuggest__input {
    font-size: 14px;

    ${media.small`
        border: 1px solid ${gray3} !important;
      `
  }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 14px;
  margin-bottom: 30px;
  label {
    margin-bottom: 12px;
    font-weight: 700;
  }

  input, select {
    width: calc(100% - 24px);
    font-size: 14px;
    padding: 6px 12px;
    border-radius: 6px;
    border: none;
    outline: none;
    box-shadow: 0 0 0 1px ${gray3};
    transition: box-shadow 0.2s;

    &:focus, &:hover {
      outline: none;
      box-shadow: 0 0 0 2px ${gray3};
    }

    &::placeholder {
      color: ${gray3};
    }

    margin-bottom: 10px;
  }
`;

const FormHeader = styled.div`
  display: flex;
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  color: ${gray11};
  justify-content: center;
  margin: 12px 0;
`;

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  position: relative;

  ${media.medium`
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  `}
  ${media.small`
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  `}
`;

const ContainerCard = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  img{
    margin-bottom: 20px;
  }
  @media(max-width: 1593px){
    margin: 70px 0 50px 0;
  }
  ${media.small`
  align-items: center;
  margin-top: 70px;
`}
`;

export const FormikForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 80%;
`;

interface IButtonSubmit {
  disabled?: boolean;
}

const ButtonSubmit = styled.button<IButtonSubmit>`
  background-color: #068660;
  color: #fff;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  font-weight: 450;
  font-size: 20px;
  padding: 10px 0 10px 0;
  font-family: 'Roboto', sans-serif;
  margin: 20px 0 20px 0;
  transition: box-shadow .5s, color .5s, background-color .5s;
  box-shadow:0px 5px 7px -6px rgba(0,0,0,0.75);

  &:hover{
    box-shadow: 0px 5px 12px -5px rgba(0,0,0,0.75);
  }
  ${media.small`
    width: 90%;
  `}

  ${({ disabled }: IButtonSubmit) => {
    if (disabled) {
      return `background-color: ${gray5}`;
    }
  }};
`;

const InformationViewQRCodePix = styled.div`
  width: 100%;
  font-weight: 500;
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
  margin: 20px 0 20px 0;
  text-align: center;
  
  > span {
    font-size: 16px;
  }
`;

const ButtonCopyQRCode = styled.button`
  background-color: #068660;
  color: #fff;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  font-weight: 450;
  font-size: 20px;
  padding: 10px;
  font-family: 'Roboto', sans-serif;
  margin: 20px 0 20px 0;
  transition: box-shadow .5s, color .5s, background-color .5s;
  box-shadow:0px 5px 7px -6px rgba(0,0,0,0.75);
  display: flex;
  align-items: center;

  &:hover{
    box-shadow: 0px 5px 12px -5px rgba(0,0,0,0.75);
  }

  > span {
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ContentCheckbox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    margin-left: 12px;
    font-weight: bold;
    font-weight: 450;
    font-size: 15px;
    cursor: pointer;
  }
`;

const WarningSave = styled.div`
  font-size: 14px;
  color: ${gray6};
  margin-bottom: 20px;
  ${media.small`
  width: 90%;
`}
`;

const ContentLastCardSave = styled.div`
  background-color: ${gray2};
  width: 30%;
  font-size: 15px;
  padding: 20px 20px 20px 20px;
`;

const ValidationFeedback = styled(CommonDiv)`
  color: red;
  font-size: 1em;
  align-items: flex-start;
  justify-content: flex-start;
  ${media.small`
    font-size: 0.9em;
  `}
`;


export {
  PaymentInformation,
  ContentLastCardSave,
  WarningSave,
  ChangeCardButton,
  ViewQRCodePixButton,
  ButtonCopyQRCode,
  InformationViewQRCodePix,
  ButtonSubmit,
  ContentCheckbox,
  OrdersWrapper,
  NextOrdersHeader,
  OldOrdersHeader,
  OrderProductCard,
  OrderProductCardInfo,
  OrderProductCardPrice,
  OrderProductCardDates,
  MoreInfoModal,
  Information,
  DeliveryInformation,
  GreenStatusInformation,
  GrayStatusInformation,
  StatusContainer,
  UseTerms,
  StateDate,
  TimeLineContainer,
  GreenTimeLine,
  GrayTimeLine,
  GreenTimeLineCircle,
  GrayTimeLineCircle,
  DeliveryButton,
  ProductItem,
  AddressBox,
  AddressGeosuggestInput,
  Wrapper,
  InputWrapper,
  FormHeader,
  AddDocumentsButton,
  RowDiv,
  ViewerBilletButton,
  ContainerCard,
  ValidationFeedback,
  Container
};

