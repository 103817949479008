import MaterialLink from '@mui/material/Link';
import React from 'react';

import logo from '../../../../assets/img/Home/allu_new_logo.png';
import assineessaideia from '../../../../assets/img/Home/assineessaideia.png';
import logointeira from '../../../../assets/img/Home/logointeira.png';
import * as S from './style';
// import logoMercadoPago from '../../../../assets/img/Footer/mercado-pago.png';
import logoPagarme from '../../../../assets/img/Footer/pagarme_oficial.png';
import imageCards from '../../../../assets/img/home2021/cards.png';
import facebook from '../../../../assets/img/home2021/facebook.svg';
import instagram from '../../../../assets/img/home2021/instagram.svg';
import tiktok from '../../../../assets/img/home2021/tiktok.svg';
import twitter from '../../../../assets/img/home2021/twitter.svg';
import whatsapp from '../../../../assets/img/home2021/whatsapp.svg';
import Config from '../../../../config';

const Footer = () => {
  return (
    <S.MainContainer>
      <S.LogoSection>
        <img src={logo} alt="shot logo" />
        <img src={assineessaideia} alt="assine essa ideia" />
      </S.LogoSection>

      <S.MenuContainer>
        <S.OptionsContainer>
          <S.Title>
            Institucional
          </S.Title>
          <S.Options>
            <a href="https://lp.allugator.com/allugator-midia/">
              Imprensa
            </a>
            <a href="https://allu.page/vagas">Trabalhe Conosco</a>
          </S.Options>
        </S.OptionsContainer>

        <S.OptionsContainer>
          <S.Title>
            Segurança
          </S.Title>
          <S.Options>
            <MaterialLink
              href={Config.termosDeUsoUrl}
              target="_blank"
              rel="noopener"
            >
              Termos de uso
            </MaterialLink>
            <MaterialLink
              href={Config.avisoProtecaoDadosUrl}
              target="_blank"
              rel="noopener"
            >
              Aviso de Proteção de Dados
            </MaterialLink>
          </S.Options>
        </S.OptionsContainer>

        <S.OptionsContainer>
          <S.Title>
            Produtos
          </S.Title>
          <S.Options>
            <MaterialLink href={process.env.RAZZLE_CATALOG_URL} target='_blank'>Produtos</MaterialLink>
          </S.Options>
        </S.OptionsContainer>

        <S.OptionsContainer>
          <S.Title>
            Contato
          </S.Title>
          <S.Options>
            <span>contato@allugator.com</span>
            <MaterialLink href="https://api.whatsapp.com/send?phone=553191095737" target="_blank" rel="noopener">
              <S.WhatsappContact>
                <img src={whatsapp} alt="whatsapp" width="29" height="29" />
                <span>+55 31 9109-5737</span>
              </S.WhatsappContact>
            </MaterialLink>
          </S.Options>
        </S.OptionsContainer>

        <S.Images>
          <S.RowImages>
            {/* <img src={logoMercadoPago} className="logo_mercadopago" alt="logo_mercadopago" /> */}
            <img src={logoPagarme} className="logo_pagarme" alt="logo_pagarme" width="183px" />
          </S.RowImages>
          <S.RowImages>
            <img src={imageCards} className="img_cards" alt="cards" />
          </S.RowImages>
        </S.Images>
      </S.MenuContainer>
      <S.ExtendedLogoContainer>
        <img className='logoextended' src={logointeira} alt='allu logo' />
      </S.ExtendedLogoContainer>
      <S.AddressContainer>
        Rua Maranhão, 330,
        Santa Efigênia - Belo Horizonte - MG
        30150-330
      </S.AddressContainer>
      <S.MidiaContainer>
        <span>CNPJ: 25.053.921/0001-43</span>
        <S.SocialLinks>
          <a href="https://www.instagram.com/allu.oficial/"><img src={instagram} alt="instagram" width="29" height="29" /></a>
          <a href="https://www.tiktok.com/@allu.oficial"><img src={tiktok} alt="tiktok" width="28" height="34" /></a>
          <a href="https://twitter.com/allu_oficial"><img src={twitter} alt="twitter" width="28" height="34" /></a>
          <a href="https://www.facebook.com/vemserallu"><img src={facebook} alt="facebook" width="15" height="29" /></a>
        </S.SocialLinks>
      </S.MidiaContainer>
      <S.CopyRight>@2023 - Allugator - Assinatura de Eletrônicos.</S.CopyRight>
    </S.MainContainer>
  )
}

export default Footer;