import { History } from 'history';
import * as React from 'react';
import { Query, QueryResult } from 'react-apollo';
import Loader from '../../../components/LoaderBuffer';
import QueryRenderer from '../../../components/QueryRenderer';
import { IQueryMyOrders, QUERY_MY_ORDERS } from '../../../graphql/queries/checkoutOrders';
import OrderProducts from './OrderProducts';
import * as moment from 'moment-timezone';
import { CheckoutOrderStatus } from '../../../graphql/types/checkoutOrders';
import { PaymentType } from '../../../actionReducers/Checkout';

type IOdersList = {
  history?: History;
  old?: Boolean;
  state?: String;
  orderOpenModal?: String;
  clearQuery(): void;
}

const OrdersList = (props: IOdersList) => {
  const { old, state, history } = props
  const SECONDS_VALIDATE_PIX = 24 * 60 * 60;
  let variables: Object = {}

  if (old !== undefined) variables['old'] = old
  if (state !== undefined) variables['state'] = state

  const isValidQRCodePix = (dateOrder: string) => {
    const now = moment.tz(moment(), 'America/Sao_Paulo');
    const orderDateTimeZoneSP = moment.tz(dateOrder, 'America/Sao_Paulo');
    const diff = now.diff(orderDateTimeZoneSP, 'seconds');
    return diff < SECONDS_VALIDATE_PIX;
  }

  const isToValidatePicpay = (order) => {
    return order.dataPayment?.payment_method === PaymentType.PICPAY && order.status === CheckoutOrderStatus.GENERATED_LINK
  }

  return (
    <Query query={QUERY_MY_ORDERS} variables={variables} fetchPolicy='no-cache'>
      {
        (result: QueryResult<IQueryMyOrders>) => (
          <QueryRenderer
            loading={<Loader />}
            error={<div>Erro! Contate nosso suporte pelo site!</div>}
            result={result}
            render={({ data, loading }: QueryResult<IQueryMyOrders>) =>
              !loading && data.myOrders ? data.myOrders.map(
                (order) => {
                  let orderEnableToShow = false;
                  if (order.status === CheckoutOrderStatus.GENERATED_PIX || isToValidatePicpay(order)) {
                    orderEnableToShow = isValidQRCodePix(order.createdAt);
                  } else {
                    orderEnableToShow = true;
                  }
                  
                  if (!orderEnableToShow) return null;
                  return (
                    <OrderProducts
                      next={true}
                      refetchOrders={result.refetch}
                      key={order._id}
                      order={order}
                      history={history}
                      orderOpenModal={props.orderOpenModal}
                      clearQuery={props.clearQuery}
                    />
                  )
                },
              ) : null
            }
          />
        )
      }
    </Query>
  );
}

export default OrdersList;
