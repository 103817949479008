import { CircularProgress } from '@material-ui/core';
import { CreditCard, Pix } from '@mui/icons-material';
import Picpay from '../../../../assets/img/picpay_logo.png';
import { Box, Radio, Stack, Typography } from '@mui/material';
import React, { ReactNode, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { PaymentMode, PaymentType } from '../../../../actionReducers/Checkout';
import { IPublicCoupon } from '../../../../graphql/types/coupons';
import { IAppState } from '../../../../store';

interface IProps {
  paymentType: PaymentType;
  currentPaymentType: PaymentType;
  coupon: IPublicCoupon;
  campaign: string;
  origin: string;
  children: ReactNode;
  cart: any;
  onClick: () => void;
}

const PaymentMethodLabel = ({ paymentType, currentPaymentType, onClick, children, origin, cart, }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [installments, setInstallments] = useState<number>(12);

  const checkPaymentType = () => paymentType === currentPaymentType;

  const handleTypePayment = (type: PaymentType) => {
    if (type === paymentType) setLoading(true);
    onClick();
  };

  useEffect(() => {
    const paymentMode = cart.items[0].paymentMode;
    if (paymentMode === PaymentMode.SEMESTER) {
      setInstallments(6)
    }
    if (paymentMode === PaymentMode.QUARTERLY) {
      setInstallments(3)
    }
  }, [])

  useEffect(() => {
    setLoading(false);
  }, [currentPaymentType]);

  const getPaymentType = () => {
    switch (paymentType) {
      case PaymentType.CREDIT_CARD:
        return <CreditCard sx={{ color: '#000000' }} />
      case PaymentType.PIX:
        return <Pix sx={{ color: '#000000' }} />
      case PaymentType.PICPAY:
        return <img src={Picpay} width={40} height={40} style={{ borderRadius: 10, padding: 3 }} />
    }
  }

  const getPaymentTypeTitle = () => {
    switch (paymentType) {
      case PaymentType.CREDIT_CARD:
        return 'Pagar com cartão de crédito'
      case PaymentType.PIX:
        return origin.toLowerCase() === 'picpay' ? 'Pagar com Pix' : 'Pagar à vista com Pix'
      case PaymentType.PICPAY:
        return 'Pagar com Picpay'
    }
  }

  const getPaymentTypeDescription = () => {
    switch (paymentType) {
      case PaymentType.CREDIT_CARD:
        return `Em até ${installments}x sem juros`
      case PaymentType.PIX:
        return `Pagamento aprovado na hora ${origin?.toLowerCase() === 'picpay' ? '\nPagando com PICPAY você consegue parcelar o PIX!!' : ''}`
      case PaymentType.PICPAY:
        return 'Parcelamento disponível'
    }
  }

  return (
    <div onClick={() => handleTypePayment(paymentType)}>
      {/* {
                currentPaymentType && !checkPaymentType() && (
                    <Typography
                        fontFamily={'Chillax'}
                        fontSize={16}
                        fontWeight={500}
                        sx={{ marginBottom: '12px' }}>
                        Outras formas de pagamento:
                    </Typography>
                )
            } */}
      <Stack
        direction={'row'}
        spacing={'16px'}
        sx={{
          backgroundColor: checkPaymentType() ? 'rgba(255, 255, 255, 0.54)' : 'rgba(255, 255, 255, 0.54)',
          borderRadius: '15px',
          padding: '12px',
          cursor: checkPaymentType() ? 'default' : 'pointer',
          border: checkPaymentType() ? '3px solid rgba(0,143,57,1)' : '1px solid rgba(0, 0, 0, 0.55)'
        }}
      >
        <Radio
          checked={checkPaymentType()}
          onChange={() => handleTypePayment(paymentType)}
          sx={{
            color: checkPaymentType() ? '#10D878' : 'rgba(0, 0, 0, 0.54)',
          }}
        />

        <Box sx={{
          display: 'flex',
          alignItems: 'center',
        }}>
          {
            loading && !checkPaymentType() ? <CircularProgress size={20} style={{ color: '#FFFFFF' }} /> : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#FFFFFF',
                  padding: '12px',
                  borderRadius: '4px',
                  maxHeight: '40px',
                  maxWidth: '40px'
                }}
              >
                {getPaymentType()}
              </Box>
            )
          }
        </Box>
        <Stack>
          <Typography
            fontFamily={'Chillax'}
            fontSize={16}
            fontWeight={500}
            color={checkPaymentType() ? 'rgba(0, 0, 0, 0.7)' : 'rgba(0, 0, 0, 0.7)'}
          >
            {getPaymentTypeTitle()}
          </Typography>
          <Typography
            fontFamily={'Chillax'}
            fontSize={16}
            fontWeight={400}
            color={checkPaymentType() ? 'rgba(0, 0, 0, 0.7)' : 'rgba(0, 0, 0, 0.7)'}
            sx={{ whiteSpace: 'pre-line' }}>
            {getPaymentTypeDescription()}
          </Typography>
          {/* {
                        paymentType === currentPaymentType && coupon && (
                            <Box sx={{ marginTop: '7px' }}>
                                <Chip
                                    label={`${coupon.amount}% de desconto`}
                                    variant="outlined"
                                    sx={{
                                        color: checkPaymentType() ? '#FFFFFF' : '#2E7D32',
                                        borderColor: checkPaymentType() ? '#FFFFFF' : '#2E7D32'
                                    }}
                                />
                            </Box>
                        )
                    } */}
        </Stack>
      </Stack>
      {
        children && (
          <Box sx={{ marginTop: paymentType === PaymentType.CREDIT_CARD ? '24px' : '12px' }}>
            {children}
          </Box>
        )
      }
    </div>
  )
};

export default connect((state: IAppState) => ({
  currentPaymentType: state.fastCheckout.paymentType,
  coupon: state.cart.coupon,
  cart: state.cart,
  campaign: state.fastCheckout.campaign,
  origin: state.fastCheckout.origin
}))(PaymentMethodLabel);
