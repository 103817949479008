import React, { useEffect, useState } from 'react';
import { Box, Card, Divider, Stack, Typography } from '@mui/material';
import SummaryAddress from '../../../Address/summary';
import { getProductShortName } from '../../../../helpers/getProductShortName';
import getStorage from '../../../../helpers/getStorageProduct';
import toReal from '../../../../helpers/toReal';
import { connect } from 'react-redux';
import { IAppState } from '../../../../store';
import { ICheckoutOrderProduct } from '../../../../graphql/types/checkoutOrders';
import { IProduct } from '../../../../graphql/types/products';
import { IPayment } from '../../../../actionReducers/Recurrence';
import { PaymentType } from '../../../../actionReducers/Checkout';
import { IUserAddress } from '../../../../graphql/types/users';

interface IProps {
    orderProduct: ICheckoutOrderProduct[];
    deliveredAddress: IUserAddress;
    paymentRecurrence: IPayment;
    paymentEntry: IPayment;
    singleCard: boolean;
}

const CardProductRecurrence = ({ orderProduct, paymentRecurrence: { amount: amountMonthly }, paymentEntry: { amount, type }, singleCard, deliveredAddress }: IProps) => {
    const [product, setProduct] = useState<IProduct>();

    useEffect(() => {
        if (!orderProduct || !orderProduct.length) return;
        setProduct(orderProduct[0].product);
    }, [orderProduct])
    return (
        <Card
            sx={{
                boxShadow: '0px 5px 8px rgba(120, 111, 111, 0.15)',
                borderRadius: '8px',
                padding: '24px',
            }}
        >
            <Typography variant={'subtitle1'} fontSize={'17px'} color={'#30313F'}>
                Endereço de Entrega
            </Typography>
            <Typography color={'#666666'} fontSize={'13px'} marginTop={'20px'}>
                <Stack spacing={'2px'}>
                    {deliveredAddress && <SummaryAddress {...deliveredAddress} />}
                </Stack>
            </Typography>
            <Divider variant="fullWidth" color="#E4E4E4" sx={{ marginY: '12px' }} />
            <Box display={'flex'}>
                {product && product.photos && product.photos.length && (
                    <img
                        src={product.photos[0]}
                        height={116}
                        width={86}
                        alt={'produto'}
                    />
                )}
                <Stack spacing={'4px'} marginLeft={'20px'}>
                    <Typography variant={'subtitle1'} fontSize={'17px'} color={'#333333'}>
                        Produto
                    </Typography>
                    <Typography variant={'subtitle2'} fontSize={'15px'} color={'#666666'}>
                        {product ? getProductShortName(product.name) : null}
                    </Typography>
                    <Typography variant={'subtitle2'} fontSize={'15px'} color={'#666666'}>
                        {product ? getStorage(product.name).label : null}
                    </Typography>
                </Stack>
            </Box>
            <Stack spacing={'4px'} sx={{paddingTop: '12px'}}>
                <Stack direction={'row'} spacing={'4px'}>
                    <Typography variant={'subtitle2'} fontSize={'15px'} color={'rgba(0, 0, 0, 0.87)'} fontWeight={500}>
                        Entrada:
                    </Typography>
                    <Typography variant={'subtitle2'} fontSize={'15px'} color={'#1E5BC6'} fontWeight={500}>
                        {amount && amount > 0 ? toReal(Number(amount)) : ''}
                    </Typography>
                </Stack>
                <Stack direction='row' spacing={'4px'}>
                    <Typography variant={'subtitle2'} fontSize={'15px'} color={'rgba(0, 0, 0, 0.87)'} fontWeight={500}>
                        Mensalidade:
                    </Typography>
                    <Typography variant={'subtitle2'} fontSize={'15px'} color={'#1E5BC6'} fontWeight={500}>
                        {type === PaymentType.BOLETO || !singleCard ? 12 : 11}&nbsp;
                        parcelas de&nbsp;{toReal(Number(amountMonthly))}
                    </Typography>
                </Stack>
            </Stack>
        </Card>
    );
};

export default connect((state: IAppState) => ({
    deliveredAddress: state.checkoutRecurrence.order.deliveryAddress,
    orderProduct: state.checkoutRecurrence.orderProduct,
    paymentEntry: state.checkoutRecurrence.paymentEntry,
    paymentRecurrence: state.checkoutRecurrence.paymentRecurrence,
    singleCard: state.checkoutRecurrence.singleCard,
}))(CardProductRecurrence);