const formatCPF = (cpf: string): string => {
    if (!cpf) return '';
    return cpf.replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/,
        function (_, arg1: string, arg2: string, arg3: string, arg4: string) {
            return arg1 + '.' + arg2 + '.' + arg3 + '-' + arg4;
        }
    );
};

export default formatCPF;